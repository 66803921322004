/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  memo,
} from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import NestedMenu from "./NestedMenu";
import { Tooltip, Modal } from "flowbite-react";
import axios from "axios";
import UserContext from "../context/UserContext";
import useCommonApi from "../hooks/common-apis";
import {
  allTemplates,
  templatesAndNavigation,
} from "../constants/TemplateAndNavigation";
import ThreeDotsLoader from "./ThreeDotsLoader";
import DeletePopup from "./DeletePopup";
import useCommonFunctions from "../hooks/common-functions";
import CreateNewSpaceModal from "./CreateNewSpaceModal";
import NestedMenuChat from "./NestedMenuChat";
import SuccessModal from "./SuccessModal";
import FailureModal from "./FailureModal";
import PopupModals from "./PopupModals";
import moment from "moment";
import socket from "./socketService";
import ReferalModalPopUp from "./ReferalModalPopUp";

const AsideBar = memo(function AsideBar({ isLoadingChat = false }) {
  const inputRef = useRef(null);
  const [loginDetails, setloginDetails] = useState({});
  const modalRef = useRef(null);
  const sideMenuRef = useRef(null);
  const documentBodyRef = useRef(null);
  const navigate = useNavigate();
  const [modelopen, setModelopen] = useState(false);
  const [sidemenuopen, setsidemenuopen] = useState(false);
  const [searchedInput, setsearchedInput] = useState("");
  const [spacesOpen, setspacesOpen] = useState(false);
  const [chatOpen, setchatOpen] = useState(false);
  const [chatOpenYoutube, setchatOpenYoutube] = useState(false);
  const context = useContext(UserContext);
  const asideRef = useRef();
  const {
    selectedWorkspace,
    setselectedWorkspace,
    setselectedTemplate,
    setselectedFile,
    recentFilesList,
    choosedTemplate,
    setchoosedTemplate,
    openModal,
    setOpenModal,
    userSpaces,
    setuserSpaces,
    openFolderCreateModal,
    setopenFolderCreateModal,
    selectedFolderStructure,
    setselectedFolderStructure,
    selectedNestedItem,
    setselectedNestedItem,
    setselectedContentSettings,
    chatCalledOnce,
    setchatCalledOnce,
    setloadingChat,
    savingFileData,
    allrefs,
  } = context;
  const tempref = useRef();
  console.log(allrefs, "all refs....");
  const [openModalRemove, setOpenModalRemove] = useState();
  const [privatepublic, setprivatepublic] = useState({
    private: false,
    public: true,
  });
  const [nestedChatState, setnestedChatState] = useState([]);
  const [nestedChatStateYoutube, setnestedChatStateYoutube] = useState([]);
  const [showModal, setshowModal] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [signUpdata, setsignUpdata] = useState({
    email: "",
    verifytxt: "",
    ref_code: "",
  });
  const [loadingReferal, setloadingReferal] = useState(false);
  const [openModalReferal, setopenModalReferal] = useState(undefined);
  const handleChange = (e) => {
    const { id, value } = e.target;
    setsignUpdata({ ...signUpdata, [id]: value });
  };
  const handleCloseSpaceModal = () => {
    setOpenModalRemove(undefined);
  };
  const {
    getRecentFiles,
    getSpaceFolderList,
    deleteFileApi,
    assignToFolderApi,
    getSpaceList,
    getWorkSpaceMembers,
    getWorkspaces,
    getChatDetails,
    chatDetails,
    getSpaceFileList,
    afRefCodeFn,
    getTrainingHistory,
  } = useCommonApi();
  const {
    getLetters,
    getBackgroundColor,
    getPlanDetails,
    checkEnabled,
    replacingWordCount,
    checkWorkspaceEnabled,
    checkInviteEnabled,
    replacingWordValue,
  } = useCommonFunctions();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [emails, setEmails] = useState([]);
  const [currentEmail, setCurrentEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [selectedMemberList, setselectedMemberList] = useState([]);
  const [openModalMembers, setopenModalMembers] = useState(undefined);
  const [selectedUserTypeId, setselectedUserTypeId] = useState("");
  const [loading, setloading] = useState(false);
  const [renameChat, setrenameChat] = useState(undefined);
  const [selectedChat, setselectedChat] = useState({});
  const chatButtonRef = useRef();
  const youtubeChatButtonRef = useRef();
  const activeChatRef = useRef();
  const dummyChatRef = useRef();
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const [deleting, setdeleting] = useState(false);
  const [popupMessage, setpopupMessage] = useState("");
  const handleInputChange = (event) => {
    setCurrentEmail(event.target.value);
    // Validate email format
    if (event.target.value == "") {
      setIsValidEmail(true);
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(event.target.value));
  };
  const todayDate = () => {
    // Get current date
    // the above code with moment
    const formattedDate = moment().format("MMMM D, YYYY");

    return formattedDate;
  };
  const getFormattedWorkspaceName = () => {
    const workspaceName = JSON.parse(
      localStorage.getItem("selected_workspace") || "{}"
    )?.workspace?.workspace_name?.trim();

    // Check if workspaceName is not null or undefined
    if (workspaceName) {
      const formattedWorkspaceName =
        workspaceName.charAt(0).toUpperCase() +
        workspaceName.slice(1).toLowerCase();
      // console.log(formattedWorkspaceName);
      return `${formattedWorkspaceName}'s Workspace`;
    } else {
      return "";
    }
  };
  // console.log(nestedChatState, "nested chat state...");

  const callUpdateToFile = async (data, template) => {
    // console.log("calling this function....", data);
    let data_assigning = localStorage.getItem("assigning_template_to_file")
      ? JSON.parse(localStorage.getItem("assigning_template_to_file"))
      : {};
    try {
      let data_incoming1 = { ...data };
      delete data_incoming1?.content_template;

      let fileName = "Untitled_";
      let todaysDate = moment().format("MMMM D, YYYY");
      // let todaysDate = moment().format("MMMM d, yyyy");
      let count = 0;
      for (let i = 0; i < recentFilesList.length; i++) {
        const element = recentFilesList[i];
        if (element.content_title?.includes("Untitled")) {
          let split = element.content_title?.split("_");
          let num = element.content_title?.split(" ")[1];
          if (num && element.content_title?.includes(todaysDate)) {
            let fileNumber = isNaN(parseInt(split[1])) ? 0 : parseInt(split[1]);
            count = Math.max(count, fileNumber);
          } else {
            count = Math.max(count, 0);
          }
        }
      }
      fileName += count + 1;
      // console.log(todayDate(), "today date....");
      // return;
      let contentData = {
        user_id: JSON.parse(localStorage.getItem("login_data"))?.resp_user?.id,
        content_id:
          localStorage.getItem("template_selected") == "from_template_file"
            ? data_assigning?.id
            : "", // if available other wise send blank
        workspace_id: JSON.parse(
          localStorage.getItem("selected_workspace") || "{}"
        )?.workspace_id, //mandatory of selected workspace
        space_id:
          localStorage.getItem("template_selected") == "from_template_file"
            ? data_assigning?.space_id
            : "", // if available other wise send blank
        folder_id:
          localStorage.getItem("template_selected") == "from_template_file"
            ? data_assigning?.folder_id
            : "", // if available other wise send blank
        content_title:
          localStorage.getItem("template_selected") == "from_template_file"
            ? data_assigning?.content_title
            : `${fileName} ${todayDate()}`,
        content_template: data?.content_template,
        contents: "",
        content_settings: {},
        input_contents: "",
        output_contents: "",
        blog_step: "",
        outline_type: "",
        no_of_outline: "",
        outline_text: {},
      };
      let response = await axios({
        url:
          localStorage.getItem("template_selected") == "from_template_file"
            ? `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/content/update`
            : `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/content/save`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: contentData,
      });
      // console.log(response, "response");
      if (!response.data.error) {
        replacingWordCount(response.data.bal_template_word);
        setselectedFile({
          ...response.data,
          content_title:
            localStorage.getItem("template_selected") == "from_template_file"
              ? data_assigning?.content_title
              : `${fileName} ${todayDate()}`,
        });
        localStorage.setItem(
          "selected_file",
          JSON.stringify({
            ...response.data,
            content_title:
              localStorage.getItem("template_selected") == "from_template_file"
                ? data_assigning?.content_title
                : `${fileName} ${todayDate()}`,
            content_template: data?.content_template,
            workspace_id: JSON.parse(
              localStorage.getItem("selected_workspace") || "{}"
            )?.workspace_id, //mandatory of selected workspace
            space_id:
              localStorage.getItem("template_selected") == "from_template_file"
                ? data_assigning?.space_id
                : "", // if available other wise send blank
            folder_id:
              localStorage.getItem("template_selected") == "from_template_file"
                ? data_assigning?.folder_id
                : "", // if available other wise send blank
          })
        );
        setselectedContentSettings({});
        localStorage.setItem("selectedContentSettings", JSON.stringify({}));
        setOpenModal(undefined);
        navigate(template.navigation);
        getRecentFiles();
        return {
          error: false,
          message: response?.data?.message,
        };
      } else {
        return {
          error: true,
          message: response?.data?.message,
        };
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const handleInputKeyDown = (event) => {
    if (!isValidEmail) {
      return;
    }
    // Handle "Enter" key to add the email
    if (event.key === "Enter" && currentEmail.trim() !== "") {
      setEmails([...emails, currentEmail.trim()]);
      setCurrentEmail("");
    }
  };
  const inviteEmail = () => {
    if (isValidEmail && currentEmail.trim() !== "") {
      setEmails([...emails, currentEmail.trim()]);
      setCurrentEmail("");
    }
  };

  const handleRemoveEmail = (index) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };

  // Function to open the modal
  const openModalHandlerDelete = () => {
    // closeMenuz();
    setOpenModalDelete("dismissible");
  };
  // Function to close the modal
  const closeModalHandlerDelete = () => {
    setOpenModalDelete(undefined);
  };
  const renameChatApi = async () => {
    setselectedChat({
      ...selectedChat,
      loading: true,
      error: null,
    });
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/thread/change_name`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          thread_id: selectedChat?.thread_id,
          thread_name: selectedChat?.title,
        },
      });
      // console.log(response, "response");
      if (!response.data.error) {
        await getChatDetails(selectedChat?.thread_type);
        setselectedChat({
          ...selectedChat,
          loading: false,
          error: null,
        });
        setrenameChat(undefined);
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
        setselectedChat({
          ...selectedChat,
          loading: false,
          error: response.data.message,
        });
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setopenFailureModal("dismissible");
      setsuccessFailureMessage(error.response.data.message);
      console.log(error);
      setselectedChat({
        ...selectedChat,
        loading: false,
        error: error.message,
      });
    }
  };
  const submitWorkspaceTeamMembers = async () => {
    try {
      setloading(true);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/invite`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          invitation_emails: emails,
          type: "workspace",
          user_type_id: selectedUserTypeId,
          workspace_id: selectedWorkspace?.workspace_id,
        },
      });
      setloading(false);
      // console.log(response, "response");
      if (!response.data.error) {
        // navigate("/template");
        setEmails([]);
        setCurrentEmail("");
        setIsValidEmail(true);
        setopenModalMembers(undefined);
        setsuccessFailureMessage(response?.data?.message);
        setopenSuccessModal("dismissible");
      } else {
        setsuccessFailureMessage(response?.data?.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      setloading(false);

      // navigate if token is expired
      if (error && error.response.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
      console.log(error);
    }
  };

  // console.log("clicking...", selectedNestedItem);
  const deleteFile = async () => {
    let id;
    let type;
    if (
      selectedNestedItem?.level == "1" &&
      selectedNestedItem?.type == "folder"
    ) {
      id = selectedNestedItem?.space_id;
      type = "space";
    } else if (
      selectedNestedItem?.level == "2" &&
      selectedNestedItem?.type == "folder"
    ) {
      id = selectedNestedItem?.folder_id;
      type = "folder";
    } else if (
      selectedNestedItem?.level == "3" ||
      selectedNestedItem?.type == "file"
    ) {
      id = selectedNestedItem.content_id || selectedNestedItem.id;
      type = "file";
    }
    // console.log(id, type, "id,itye");
    setdeleting(true);
    try {
      let response = await deleteFileApi(id, type);
      setdeleting(false);
      if (!response?.error) {
        closeModalHandlerDelete();
        setsuccessFailureMessage(response.message);
        setopenSuccessModal("dismissible");
        if (
          selectedNestedItem?.level == "1" &&
          selectedNestedItem?.type == "folder"
        ) {
          getSpaceList();
        } else if (
          selectedNestedItem?.level == "2" &&
          selectedNestedItem?.type == "folder"
        ) {
          getSpaceFolderList(selectedNestedItem?.space_id);
        } else if (
          selectedNestedItem?.level == "3" ||
          selectedNestedItem?.type == "file"
        ) {
          if (selectedNestedItem?.level == "2") {
            getSpaceFolderList(selectedNestedItem?.space_id);
          } else {
            getSpaceFileList(
              selectedNestedItem?.space_id,
              selectedNestedItem?.folder_id
            );
          }
        }
      } else {
        setsuccessFailureMessage(response?.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      setdeleting(false);
      if (error && error.response.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
      console.log(error);
    }
  };
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  // console.log(selectedNestedItem, "selectedNestedItem");

  const location = useLocation();
  // console.log(location, "loaction....");

  const moreLink = (env, id) => {
    if (!env.target.matches(".active")) {
      document.querySelectorAll(".nav-toggle").forEach((e) => {
        e.classList.remove("active");
      });
      document.querySelectorAll(".navmore-link").forEach((e) => {
        e.classList.remove("active");
      });
      env.target.classList.add("active");
      document.getElementById(id).classList.add("active");
    } else {
      env.target.classList.remove("active");
      document.getElementById(id).classList.remove("active");
    }
  };
  const menuToggle = () => {
    document.body.classList.toggle("shrink-menu");
  };
  const navigateFromFile = (file) => {
    const getNavigation = (itemTitle) => {
      for (const category in templatesAndNavigation) {
        const categoryItems = templatesAndNavigation[category];
        const selectedItem = categoryItems.find(
          (item) => item.title === itemTitle
        );

        if (selectedItem) {
          return selectedItem.navigation;
        }
      }

      return "Navigation not found";
    };
    let titleObjNav = getNavigation(file?.content_template);
    if (titleObjNav == "Navigation not found") {
      setselectedFile({
        ...file,
        newFile: true,
      });
      localStorage.setItem(
        "selected_file",
        JSON.stringify({
          ...file,
          newFile: true,
        })
      );
      setselectedContentSettings({});
      // content_template: file?.title,
      //   content_title: file?.content_title,
      //   content_id: file?.id,
      //   space_id: file?.space_id,
      //   folder_id: file?.folder_id,
      localStorage.setItem("selectedContentSettings", JSON.stringify({}));
      localStorage.setItem("template_selected", "from_template_file");
      localStorage.setItem("assigning_template_to_file", JSON.stringify(file));
      setchoosedTemplate("Blog");
      setOpenModal("dismissible");
    } else {
      localStorage.setItem("selected_file", JSON.stringify(file));
      setselectedFile(file);
      navigate(titleObjNav);
    }
  };

  // Freez side bar opne dropdown
  const getShortCutName = (name1, name2) => {
    if (!name1 || name1 == "") return "";
    let shortCut = "";
    shortCut += name1[0]?.toUpperCase();
    if (name2 != "") {
      shortCut += name2[0]?.toUpperCase();
    }
    return shortCut;
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModelopen(false);
      }
    }
    if (modelopen) {
      inputRef?.current?.focus();
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modelopen]);
  useEffect(() => {
    if (openModalDelete == "dismissible") {
      return;
    }
    function handleClickOutside(event) {
      if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
        setsidemenuopen(false);
        closeMenuz();
      }
    }
    if (sidemenuopen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      if (openModalDelete != "dismissible") {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, [sidemenuopen]);
  useEffect(() => {
    setloginDetails({
      ...JSON.parse(localStorage.getItem("login_data")),
    });
  }, []);
  const submitWorkspace = async () => {
    setselectedFolderStructure({
      ...selectedFolderStructure,
      loading: true,
      error: null,
    });
    let emails = [];
    for (let i = 0; i < selectedMemberList.length; i++) {
      const element = selectedMemberList[i];
      emails.push(element?.user_workspace?.email);
    }
    let data = {};
    if (selectedFolderStructure?.mainHeading == "Update Space") {
      data = {
        workspace_id: selectedWorkspace?.workspace_id,
        space_name: selectedFolderStructure?.value,
        space_id: selectedNestedItem?.space_id,
        space_type: 0,
        space_icon: "",
        space_avtar: "",
        invitation_emails: [],
      };
    } else {
      data = {
        workspace_id: JSON.parse(
          localStorage.getItem("selected_workspace") || "{}"
        )?.workspace_id,
        space_name: selectedFolderStructure?.value,
        space_type: privatepublic.public ? 0 : 1,
        space_icon: "",
        space_avtar: "",
        invitation_emails: privatepublic.public ? [] : emails,
      };
    }
    try {
      let response = await axios({
        url:
          selectedFolderStructure?.mainHeading == "Update Space"
            ? `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/workspace/space/update`
            : `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/workspace/space/create`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: data,
      });
      // console.log(response, "response");
      setselectedFolderStructure({
        ...selectedFolderStructure,
        loading: false,
        error: null,
      });
      if (!response.data.error) {
        getSpaceList();
        // setopenFolderCreateModal(undefined);
        handleCloseSpaceModal();
        setEmails([]);
        setselectedMemberList([]);
        setprivatepublic({
          ...privatepublic,
          public: true,
          private: false,
        });
        setopenFolderCreateModal(undefined);
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
        setselectedFolderStructure({
          ...selectedFolderStructure,
          loading: false,
          error: response.data.message,
        });
      }
    } catch (error) {
      setselectedFolderStructure({
        ...selectedFolderStructure,
        loading: true,
        error: error.message,
      });
      if (error && error.response.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
      console.log(error);
    }
  };
  const submitWorkspaceFolder = async () => {
    setselectedFolderStructure({
      ...selectedFolderStructure,
      loading: true,
      error: null,
    });
    let data = {};
    if (selectedFolderStructure?.mainHeading == "Update Folder") {
      data = {
        folder_id: selectedNestedItem?.folder_id,
        space_id: selectedNestedItem?.space_id,
        folder_name: selectedFolderStructure?.value,
        folder_icon: "",
        folder_avtar: "",
      };
    } else {
      data = {
        space_id: selectedNestedItem?.space_id,
        folder_name: selectedFolderStructure?.value,
        folder_icon: "",
        folder_avtar: "",
      };
    }
    try {
      let response = await axios({
        url:
          selectedFolderStructure?.mainHeading == "Update Folder"
            ? `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/workspace/space/folder/update`
            : `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/workspace/space/folder/create`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: data,
      });
      // console.log(response, "response");
      setselectedFolderStructure({
        ...selectedFolderStructure,
        loading: false,
        error: null,
      });
      if (!response.data.error) {
        getSpaceFolderList(selectedNestedItem?.space_id);
        setopenFolderCreateModal(undefined);
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
        setselectedFolderStructure({
          ...selectedFolderStructure,
          loading: false,
          error: response.data.message,
        });
      }
    } catch (error) {
      setselectedFolderStructure({
        ...selectedFolderStructure,
        loading: false,
        error: error.message,
      });
      if (error && error.response.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
      console.log(error);
    }
  };
  // console.log(selectedNestedItem, "selecetd nested item...");
  const submitWorkspaceFolderFIle = async () => {
    setselectedFolderStructure({
      ...selectedFolderStructure,
      loading: true,
      error: null,
    });
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/api/user/content/save`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("login_token"),
        },
        data: {
          user_id: JSON.parse(localStorage.getItem("login_data"))?.resp_user
            ?.id,
          content_id: "",
          workspace_id: JSON.parse(
            localStorage.getItem("selected_workspace") || "{}"
          )?.workspace_id, //mandatory of selected workspace
          space_id: selectedNestedItem.space_id || "", // if available other wise send blank
          folder_id: selectedNestedItem.folder_id || "", // if available other wise send blank
          content_title: selectedFolderStructure?.value,
          content_template: "",
          contents: "",
          content_settings: {},
        },
      });
      // console.log(response, "response");
      setselectedFolderStructure({
        ...selectedFolderStructure,
        loading: true,
        error: null,
      });
      if (!response.data.error) {
        replacingWordCount(response.data.bal_template_word);
        if (selectedNestedItem?.level === "1") {
          getSpaceFolderList(selectedNestedItem?.space_id);
        } else {
          getSpaceFileList(
            selectedNestedItem?.space_id,
            selectedNestedItem?.folder_id
          );
        }
        setopenFolderCreateModal(undefined);
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
        setselectedFolderStructure({
          ...selectedFolderStructure,
          loading: true,
          error: response.data.message,
        });
      }
    } catch (error) {
      setselectedFolderStructure({
        ...selectedFolderStructure,
        loading: true,
        error: error.message,
      });
      if (error && error.response.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
      console.log(error);
    }
  };

  // console.log(userSpaces, "userspaces................");
  const updateItems = (items, selectedTreeId, state) => {
    return items.map((item) => {
      if (item.tree_id === selectedTreeId) {
        // If the tree_id matches, update the item with newData
        return {
          ...item,
          open: state,
        };
      } else if (item.submenu && item.submenu.length > 0) {
        // If the item has children, recursively update them
        return {
          ...item,
          submenu: updateItems(item.submenu, selectedTreeId, state),
        };
      }
      // If no match and no children, return the original item
      return item;
    });
  };
  const handleUpdateTree = async (
    selectedTreeId,
    state,
    from = "space",
    item = {}
  ) => {
    let updatedUserItems = [];
    if (from === "chat") {
      if (item?.type == "file") {
        localStorage.removeItem("chat_details");
        await chatDetails(item?.thread_id, "general");
        localStorage.setItem(
          "chat_file",
          JSON.stringify({
            thread_id: item?.thread_id,
            thread_name: item?.thread_name,
          })
        );
        // navigate("/template/chat");
      }
      updatedUserItems = updateItems(nestedChatState, selectedTreeId, state);
      // setselectedNestedItem(item);
      setnestedChatState(updatedUserItems);
    } else if (from === "youtubechat") {
      if (item?.type == "file") {
        localStorage.removeItem("chat_details");
        await chatDetails(item?.thread_id, "youtube");
        localStorage.setItem(
          "chat_file",
          JSON.stringify({
            thread_id: item?.thread_id,
            thread_name: item?.thread_name,
          })
        );
        // navigate("/template/youtube-chat");
      }
      updatedUserItems = updateItems(
        nestedChatStateYoutube,
        selectedTreeId,
        state
      );
      setnestedChatStateYoutube(updatedUserItems);
    } else {
      updatedUserItems = updateItems(userSpaces, selectedTreeId, state);
      setuserSpaces(updatedUserItems);
    }
  };
  // const openMenuz = (e, item) => {
  //   setsidemenuopen(true);
  //   console.log(item, "item...");
  //   setselectedNestedItem(item);
  //   let fromLeft = e.target.offsetLeft;
  //   let fromTop = e.target.offsetTop;
  //   document.querySelector(".dropdown-aside").style.display = "block";
  //   document.querySelector(".dropdown-aside").style.left = fromLeft + "px";
  //   document.querySelector(".dropdown-aside").style.top = fromTop + "px";
  //   document.getElementById("asideBar").classList.add("scroll-disabled");
  // };
  const openMenuz = (e, item) => {
    setsidemenuopen(true);
    console.log(item, "item...");
    setselectedNestedItem(item);

    const buttonElement = e.currentTarget; // Use currentTarget to get the element the event handler is attached to
    const rect = buttonElement.getBoundingClientRect();
    const fromLeft = rect.left + window.scrollX; // Adding scrollX to account for any horizontal scroll
    const fromTop = rect.top + window.scrollY; // Adding scrollY to account for any vertical scroll

    const dropdownAside = document.querySelector(".dropdown-aside");
    dropdownAside.style.display = "block";
    dropdownAside.style.left = `${fromLeft}px`;
    dropdownAside.style.top = `${fromTop}px`;

    document.getElementById("asideBar").classList.add("scroll-disabled");
  };

  const closeMenuz = (e) => {
    document.querySelector(".dropdown-aside").style.display = "none";
    document.getElementById("asideBar").classList.remove("scroll-disabled");
  };
  useEffect(() => {
    setnestedChatState(chatCalledOnce?.general_folders);
    setnestedChatStateYoutube(chatCalledOnce?.youtube_folders);
  }, [chatCalledOnce?.general_folders, chatCalledOnce?.general_folders?.length, chatCalledOnce?.youtube_folders, chatCalledOnce?.youtube_folders?.length]);
  console.log(chatCalledOnce, "chat called once...");

  useEffect(() => {
    documentBodyRef.current = document.body;
  }, []);
  // console.log(nestedChatStateYoutube, "nested chat state youtube...");

  const getWorkspaceList = () => {
    let localStorageWorkspaces = localStorage.getItem("workspaces_list");
    if (
      localStorageWorkspaces &&
      Array.isArray(JSON.parse(localStorageWorkspaces))
    ) {
      return JSON.parse(localStorageWorkspaces);
    } else {
      return [];
    }
  };
  const getWorkspaceGraphql = useCallback(async () => {
    try {
      let workspaces = await getWorkspaces();
      // setselectedWorkspace(workspaces[0]);
      if (workspaces && Array.isArray(workspaces)) {
        localStorage.setItem("workspaces_list", JSON.stringify(workspaces));
      } else {
        localStorage.setItem("workspaces_list", JSON.stringify([]));
        localStorage.setItem("selected_workspace", JSON.stringify({}));
      }
      // localStorage.setItem("selected_workspace", JSON.stringify(workspaces[0]));
      let allWorkSpaces = workspaces || [];
      let loginData = JSON.parse(localStorage.getItem("login_data")) || {};
      let loginWorkSpace = loginData?.resp_user?.workspace_id;
      let selected_workspace =
        JSON.parse(localStorage.getItem("selected_workspace")) || {};
      if (
        selected_workspace &&
        Object.keys(selected_workspace || {}).length > 0
      ) {
        setselectedWorkspace(selected_workspace);
      } else {
        if (loginWorkSpace) {
          let selectedWorkspace = allWorkSpaces.filter(
            (item) => item?.workspace_id == loginWorkSpace
          )[0];
          setselectedWorkspace(selectedWorkspace || {});
          localStorage.setItem(
            "selected_workspace",
            JSON.stringify(selectedWorkspace || {})
          );
        } else {
          setselectedWorkspace(allWorkSpaces[0] || {});
          localStorage.setItem(
            "selected_workspace",
            JSON.stringify(allWorkSpaces[0] || {})
          );
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);
  const renameFileApi = async () => {
    try {
      let response = await assignToFolderApi(
        selectedNestedItem?.id,
        selectedNestedItem?.space_id,
        selectedNestedItem?.folder_id,
        selectedFolderStructure?.value
      );

      if (!response?.error) {
        setopenFolderCreateModal(undefined);
        setsuccessFailureMessage(response.message);
        setopenSuccessModal("dismissible");
        getSpaceFileList(
          selectedNestedItem?.space_id,
          selectedNestedItem?.folder_id
        );
      } else {
        setsuccessFailureMessage(response.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        localStorage.removeItem("login_token");
        localStorage.removeItem("login_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
      console.log(error);
    }
  };
  // console.log(workspaceList, "workspaceList");
  // console.log(userSpaces, "user spaces...");

  // console.log(selectedWorkspace, "workspace");
  // console.log(loginDetails, "login details");
  const [membersList, setmembersList] = useState([]);
  const [membersListTotal, setmembersListTotal] = useState({});
  const getMembers = async () => {
    let membersResult = await getWorkSpaceMembers(
      selectedWorkspace?.workspace_id
    );
    setmembersList(membersResult?.members?.members);
    setmembersListTotal(membersResult?.members);
  };

  const checkByReplace = (template) => {
    let title = template?.title;
    let lowerCaseAndReplace = title?.toLowerCase()?.replaceAll(" ", "_");
    return checkEnabled(lowerCaseAndReplace);
  };
  const checkByReplaceMain = (template) => {
    let title = template?.title;
    let lowerCaseAndReplace = title?.toLowerCase()?.replaceAll(" ", "_");
    return checkEnabled(`template_${lowerCaseAndReplace}`);
  };

  // console.log(membersList, "member list");
  useEffect(() => {
    if (
      selectedWorkspace?.workspace_id &&
      location.pathname.includes("/template")
    ) {
      getMembers();
      getSpaceList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkspace?.workspace_id]);

  const asideBarType = () => {
    let pathname = location.pathname;
    let pathnamesArray = [
      "/account-settings",
      "/member",
      "/manage-space",
      "/billing",
      "/social-connect",
      "/my-settings",
      "/my-workspace",
      "/subscription",
    ];
    if (pathnamesArray.includes(pathname)) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (location.pathname.includes("/template")) {
      getWorkspaceGraphql();
    }
  }, []);
  useEffect(() => {
    if (
      !location.pathname.includes("/chat") &&
      location.pathname.includes("/youtube-chat")
    ) {
      setloadingChat(false);
    }
  }, []);
  useEffect(() => {
    // document.body.classList.add("busy-cursor");
  }, []);

  useEffect(() => {
    getChatDetails("general");
    setchatCalledOnce({
      ...chatCalledOnce,
      general: true,
    });
    getChatDetails("youtube");
    setchatCalledOnce({
      ...chatCalledOnce,
      youtube: true,
    });
    if (getPlanDetails().planId == 3) {
      getTrainingHistory();
    }
  }, []);
  const getChatTreeId = (array) => {
    let paramId = new URLSearchParams(window.location.search).get("id");
    // console.log(paramId, "param id in aside bar");
    // console.log(array, "nested chat state");

    if (array?.length) {
      for (let i = 0; i < array?.length; i++) {
        let folder = array[i];
        let submenu = folder?.submenu;
        // console.log(submenu, "submenu came inside if");
        if (submenu?.length) {
          for (let j = 0; j < submenu?.length; j++) {
            let submenuItem = submenu[j];
            if (submenuItem?.thread_id == paramId) {
              // console.log(submenuItem?.tree_id, "tree id came inside if");
              return folder?.tree_id;
            }
          }
        }
      }
      return "";
    }
  };
  useEffect(() => {
    if (location.pathname == "/template/chat") {
      chatButtonRef.current.click();
      chatButtonRef.current.classList.add("active");
      document.getElementById("more_chat").classList.add("active");
      let selectedTreeId = getChatTreeId(nestedChatState);
      // console.log(selectedTreeId, "selected tree id");
      if (selectedTreeId) {
        let updatedUserItems = updateItems(
          nestedChatState,
          selectedTreeId,
          true
        );
        setnestedChatState(updatedUserItems);
        activeChatRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [location.pathname, nestedChatState.length]);
  const callReferalApi = async () => {
    afRefCodeFn(localStorage.getItem("ref_code"));
    setopenModalReferal(undefined);
  };

  useEffect(() => {
    if (localStorage.getItem("continue_with_google")) {
      if (localStorage.getItem("ref_code")) {
        callReferalApi(localStorage.getItem("ref_code"));
      } else {
        setopenModalReferal("dismissible");
      }
    }
  }, []);

  useEffect(() => {
    if (location.pathname == "/template/youtube-chat") {
      youtubeChatButtonRef.current.click();
      youtubeChatButtonRef.current.classList.add("active");
      document.getElementById("more_chatyoutube").classList.add("active");
      let selectedTreeId = getChatTreeId(nestedChatStateYoutube);
      // console.log(selectedTreeId, "selected tree id");
      if (selectedTreeId) {
        let updatedUserItems = updateItems(
          nestedChatStateYoutube,
          selectedTreeId,
          true
        );
        setnestedChatStateYoutube(updatedUserItems);
        activeChatRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
    if (
      !location.pathname.includes("/chat") &&
      !location.pathname.includes("/youtube-chat")
    ) {
      localStorage.removeItem("chat_file");
      localStorage.setItem("chat_details", JSON.stringify([]));
    }
  }, [location.pathname, nestedChatStateYoutube.length]);

  useEffect(() => {
    socket.on("connect", () => {
      const userId = JSON.parse(localStorage.getItem("login_data"))?.resp_user
        ?.id; // Replace with actual user ID
      socket.emit("join", userId);
    });
    socket.on("seat_notification", (message) => {
      console.log("Received seat:", message.seat);
      replacingWordValue("seat", message.seat);
    });
    socket.on("work_space_notification", (message) => {
      console.log("Received workspace:", message.work_space);
      replacingWordValue("work_space", message.work_space);
    });
    // error
    socket.on("error", (err) => {
      console.log("Error: ", err);
    });
    return () => {
      socket.off("connect");
      socket.off("seat_notification");
      socket.off("work_space_notification");
      socket.off("error");
    };
  }, []);

  // console.log(chatCalledOnce, "chat cled one");

  // console.log(selectedContentSettings, "selected content settings...");

  return (
    <>
      {!asideBarType() && (
        <aside className="flex flex-col justify-between" id="asideBar">
          <div className="h-full">
            <div className="relative  h-[72%] ">
              <div className="h-full">
                <div className="dashboard-logo sticky top-0 z-50 bg-[#FAFBFC] h-[20%] flex items-center">
                  <Link to="/template">
                    {/* <img
                      src={`${window.location.origin}/images/vison_priorlogo.png`}
                      alt="logo"
                    /> */}
                    {/* <img
                      src={`${window.location.origin}/images/VisonLogoNew.png`}
                      alt="logo"
                    /> */}
                    <img
                      src={`${window.location.origin}/images/VisonWithOReplaced.png`}
                      alt="logo"
                    />
                  </Link>
                  <button type="button" onClick={() => menuToggle()}>
                    <svg className="icon">
                      <use href="#icon_menu"></use>
                    </svg>
                  </button>
                </div>
                <ul className="h-[80%]" ref={asideRef}>
                  <li
                    ref={allrefs?.home}
                    onClick={(e) => {
                      setchatOpen(false);
                      setchatOpenYoutube(false);
                      // moreLink(e, "more_chat");
                    }}
                  >
                    <NavLink className="nav-link " to="/template">
                      <span>
                        <svg className="icon">
                          <use href="#icon_home"></use>
                        </svg>
                      </span>
                      <strong>Home</strong>
                    </NavLink>
                  </li>
                  <li>
                    <div
                      ref={allrefs?.templates}
                      className="nav-link"
                      onClick={() => {
                        setchatOpen(false);
                        setchatOpenYoutube(false);
                        let workspaceList =
                          JSON.parse(localStorage.getItem("workspaces_list")) ||
                          [];
                        if (workspaceList.length === 0) {
                          setModelopen(true);
                          return;
                        }
                        localStorage.setItem(
                          "template_selected",
                          "from_asidebar"
                        );
                        setOpenModal("dismissible");
                      }}
                    >
                      <span>
                        <svg className="icon">
                          <use href="#icon_template"></use>
                        </svg>
                      </span>
                      <strong>Templates</strong>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <div
                        className="nav-link nav-toggle flex items-center relative w-[100%]"
                        onClick={(e) => {
                          setchatOpen(false);
                          setchatOpenYoutube(!chatOpenYoutube);
                          moreLink(e, "more_chatyoutube");
                        }}
                        ref={youtubeChatButtonRef}
                      >
                        <span>
                          <svg className="icon">
                            <use href="#icon_youtubechat"></use>
                          </svg>
                        </span>
                        <strong>
                          Youtube Chat{" "}
                          <sup className={`absolute top-2 right-8`}>[BETA]</sup>
                        </strong>
                      </div>
                      <div>
                        <button
                          ref={allrefs?.youtube}
                          className="mx-auto mt-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            localStorage.removeItem("chat_file");
                            localStorage.setItem(
                              "chat_details",
                              JSON.stringify([])
                            );
                            navigate("/template/youtube-chat");
                          }}
                        >
                          <svg className="icon">
                            <use href="#icon_plus"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="navmore-link" id="more_chatyoutube">
                      <div className="nestaccordion">
                        <NestedMenuChat
                          nestedchat={nestedChatStateYoutube}
                          handleUpdateTree={handleUpdateTree}
                          openMenuz={openMenuz}
                          closeMenuz={closeMenuz}
                          from={"youtubechat"}
                          setrenameChat={setrenameChat}
                          setselectedChat={setselectedChat}
                        />
                      </div>
                    </div>
                  </li>
                  <li className="chat-line">
                    <div className="w-[100%] flex items-center">
                      <div
                        className="nav-link nav-toggle flex items-center relative w-[100%]"
                        onClick={(e) => {
                          setchatOpen(!chatOpen);
                          setchatOpenYoutube(false);
                          moreLink(e, "more_chat");
                        }}
                        ref={chatButtonRef}
                      >
                        <span>
                          <svg className="icon">
                            <use href="#icon_chat"></use>
                          </svg>
                        </span>
                        <strong>
                          Chat{" "}
                          <sup className={`absolute top-2 left-18`}>[BETA]</sup>
                        </strong>
                      </div>
                      <div className="icon_plus">
                        <button
                          ref={allrefs?.chat}
                          className="mx-auto mt-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            localStorage.removeItem("chat_file");
                            localStorage.setItem(
                              "chat_details",
                              JSON.stringify([])
                            );
                            navigate("/template/chat");
                          }}
                        >
                          <svg className="icon">
                            <use href="#icon_plus"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="navmore-link" id="more_chat">
                      <div className="nestaccordion">
                        <NestedMenuChat
                          nestedchat={nestedChatState}
                          handleUpdateTree={handleUpdateTree}
                          openMenuz={openMenuz}
                          closeMenuz={closeMenuz}
                          from={"chat"}
                          setrenameChat={setrenameChat}
                          setselectedChat={setselectedChat}
                          activeChatRef={activeChatRef}
                          dummyChatRef={dummyChatRef}
                        />
                      </div>
                    </div>
                  </li>

                  <li ref={allrefs?.training}>
                    <div
                      className="nav-link"
                      onClick={() => {
                        navigate("/model-training");
                      }}
                    >
                      <span>
                        <svg className="icon">
                          <use href="#icon_training"></use>
                        </svg>
                      </span>
                      <strong>Model Training</strong>
                    </div>
                  </li>
                  {/* <li>
                    <div
                      className="nav-link nav-toggle"
                      onClick={(e) => {
                        setchatOpen(false);
                        setchatOpenYoutube(false);
                        moreLink(e, "more1");
                      }}
                    >
                      <span>
                        <svg className="icon">
                          <use href="#icon_bookmark"></use>
                        </svg>
                      </span>
                      <strong>Bookmarks</strong>
                    </div>
                    <div className="navmore-link" id="more1">
                      <NavLink to="/">One</NavLink>
                      <NavLink to="/">Two</NavLink>
                    </div>
                  </li> */}

                  <li ref={allrefs?.space}>
                    <div
                      className="nav-link nav-toggle"
                      onClick={(e) => {
                        setchatOpen(false);
                        setchatOpenYoutube(false);
                        setspacesOpen(!spacesOpen);
                        setTimeout(() => {
                          const asideContainer = asideRef.current;

                          // Scroll to the bottom
                          asideContainer.scrollTop =
                            asideContainer.scrollHeight;
                        }, 10);
                        moreLink(e, "more2");
                      }}
                    >
                      <span>
                        <svg className="icon">
                          <use href="#icon_space"></use>
                        </svg>
                      </span>
                      <strong>Spaces</strong>
                    </div>
                    <div className="navmore-link" id="more2">
                      <button
                        type="button"
                        className="w-full my-3 border border-[#BDBDBD] text-[#BDBDBD] rounded-[20px] p-1 hover:text-[#1A71A7] hover:border-[#1A71A7]"
                        onClick={() => {
                          setselectedFolderStructure({
                            type: "space",
                            value: "",
                            title: "Space Name",
                            placeholder: "Enter your space name",
                            mainHeading: "Create Space",
                            loading: false,
                            error: null,
                          });
                          setselectedNestedItem(null);
                          setOpenModalRemove("dismissible");
                        }}
                      >
                        + New Space
                      </button>
                      <div className="nestaccordion">
                        {userSpaces?.length > 0 && (
                          <NestedMenu
                            userSpaces={userSpaces}
                            getSpaceFolderList={getSpaceFolderList}
                            getSpaceFileList={getSpaceFileList}
                            handleUpdateTree={handleUpdateTree}
                            openMenuz={openMenuz}
                            closeMenuz={closeMenuz}
                            navigateFromFile={navigateFromFile}
                          />
                        )}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="absolute bottom-0  h-[28%]">
              <div className="py-[20px] mx-4">
                <div
                  ref={allrefs?.invite}
                  className="mb-6 pb-6 border-b w-full"
                >
                  <button
                    type="button"
                    className="border border-[#2A2A2A] rounded-[40px] py-[8px] px-[10px] flex items-center w-full invite-box hover:text-[#097BA9] hover:border-[#097BA9]"
                    onClick={() => {
                      if (!checkInviteEnabled()) {
                        setshowModal({
                          first: false,
                          second: true,
                          third: false,
                        });
                        return;
                      }
                      setEmails([]);
                      setloading(false);
                      setCurrentEmail("");
                      setIsValidEmail(true);
                      setopenModalMembers("dismissible");
                    }}
                  >
                    <svg className="icon text-[24px]">
                      <use href="#icon_invite"></use>
                    </svg>
                    <span className="mx-4">Invite Team Member</span>
                  </button>
                </div>
                <div
                  ref={allrefs?.workspace}
                  className="w-full aside-modelbtn active"
                >
                  <button
                    onClick={() => setModelopen(true)}
                    type="button"
                    className="w-full flex flex-row items-center justify-between hover:text-[#1A71A7]"
                  >
                    <div className="flex flex-row items-center">
                      <div
                        style={{
                          backgroundColor: getBackgroundColor(
                            localStorage.getItem("selected_workspace")
                              ? JSON.parse(
                                  localStorage.getItem("selected_workspace") ||
                                    "{}"
                                )?.workspace?.workspace_name?.charAt(0) || ""
                              : ""
                          ),
                        }}
                        className="w-[50px] h-[50px] bg-[#60CFFF] text-[#fff] font-semibold text-[20px] rounded-full flex items-center justify-center"
                      >
                        {getLetters(
                          JSON.parse(
                            localStorage.getItem("selected_workspace") || "{}"
                          )?.workspace?.workspace_name
                        )}
                      </div>
                      <div>
                        <span className="mx-4 break-all">
                          {JSON.parse(
                            localStorage.getItem("selected_workspace") || "{}"
                          )?.workspace?.workspace_name?.toUpperCase() ||
                            "Create Workspace"}
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <svg className="icon text-[12px]">
                        <use href="#icon_downarrow"></use>
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </aside>
      )}
      {asideBarType() && (
        <aside className="flex flex-col justify-between" id="asideBar">
          <div className="h-full flex flex-col justify-between">
            <div className="relative  lh-[72%]">
              <div className="h-full">
                <div className="px-[30px] relative">
                  <div
                    className="flex items-center cursor-pointer font-semibold py-[25px] sticky top-0 z-50 bg-[#FAFBFC]"
                    onClick={() => navigate("/template")}
                  >
                    <button type="button" className="hover:text-[#1A71A7] me-3">
                      <svg className="icon text-[26px]">
                        <use href="#icon_backarrow"></use>
                      </svg>
                    </button>
                    Back
                  </div>
                  <div>
                    <h5 className="font-bold text-[22px] pb-[20px]">
                      Settings
                    </h5>
                  </div>
                  <div className="w-full aside-modelbtn active pb-4">
                    <button
                      type="button"
                      className="flex items-center hover:text-[#1A71A7]"
                    >
                      <div
                        style={{
                          backgroundColor: getBackgroundColor(
                            JSON.parse(
                              localStorage.getItem("selected_workspace") || "{}"
                            )?.workspace?.workspace_name?.charAt(0) || ""
                          ),
                        }}
                        className="min-w-[50px] min-h-[50px] w-[50px] h-[50px] bg-[#60CFFF] text-[#fff] font-semibold text-[20px] rounded-full flex items-center justify-center "
                      >
                        {getLetters(
                          JSON.parse(
                            localStorage.getItem("selected_workspace") || "{}"
                          )?.workspace?.workspace_name
                        )}
                      </div>
                      <span className="ms-1 inline-block">
                        {getFormattedWorkspaceName()}
                      </span>
                    </button>
                  </div>
                </div>
                <ul
                  className="h-[80%] overflow-hidden overflow-y-auto"
                  ref={asideRef}
                >
                  <li className="pb-[5px]">
                    <NavLink className="nav-link" to="/account-settings">
                      <span>
                        <svg className="icon">
                          <use href="#icon_user"></use>
                        </svg>
                      </span>
                      <strong>Account Settings</strong>
                    </NavLink>
                  </li>
                  <hr />
                  <li className="pb-[5px]">
                    <NavLink className="nav-link" to="/member">
                      <span>
                        <svg className="icon">
                          <use href="#icon_member-group"></use>
                        </svg>
                      </span>
                      <strong>Members</strong>
                    </NavLink>
                  </li>
                  <hr />
                  <li className="pb-[5px]">
                    <NavLink className="nav-link" to="/manage-space">
                      <span>
                        <svg className="icon">
                          <use href="#icon_space"></use>
                        </svg>
                      </span>
                      <strong>Spaces</strong>
                    </NavLink>
                  </li>
                  <hr />
                  <li className="pb-[5px]">
                    <NavLink className="nav-link" to="/subscription">
                      <span>
                        <svg className="icon">
                          <use href="#icon_subscription"></use>
                        </svg>
                      </span>
                      <strong>Subscription</strong>
                    </NavLink>
                  </li>
                  <hr />
                  <li className="pb-[5px]">
                    <NavLink className="nav-link" to="/billing">
                      <span>
                        <svg className="icon">
                          <use href="#icon_bill"></use>
                        </svg>
                      </span>
                      <strong>Billing</strong>
                    </NavLink>
                  </li>
                  <hr />
                  <li className="pb-[5px]">
                    <NavLink className="nav-link" to="/social-connect">
                      <span>
                        <svg className="icon">
                          <use href="#icon_social-connect"></use>
                        </svg>
                      </span>
                      <strong>Social Connect</strong>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="">
              <ul className="">
                <li className="pt-[10px] font-[500] text-[18px] pl-4 assign-name mb-[-5px]">
                  {JSON.parse(localStorage.getItem("login_data"))?.resp_user
                    ?.first_name +
                    " " +
                    JSON.parse(localStorage.getItem("login_data"))?.resp_user
                      ?.last_name}
                </li>
                <li className="">
                  <NavLink className="nav-link" to="/my-settings">
                    <span>
                      <svg className="icon">
                        <use href="#icon_setting"></use>
                      </svg>
                    </span>
                    <strong>My Settings</strong>
                  </NavLink>
                </li>
                <li className="">
                  <NavLink className="nav-link" to="/my-workspace">
                    <span>
                      <svg className="icon">
                        <use href="#icon_workspace"></use>
                      </svg>
                    </span>
                    <strong>Workspaces</strong>
                  </NavLink>
                </li>
                {/* <li className="">
                  <NavLink className="nav-link" to="/">
                    <span>
                      <svg className="icon">
                        <use href="#icon_notification"></use>
                      </svg>
                    </span>
                    <strong>Notifications</strong>
                  </NavLink>
                </li> */}
                <li
                  className=""
                  onClick={() => {
                    localStorage.removeItem("login_data");
                    localStorage.removeItem("login_token");
                    localStorage.removeItem("selected_workspace");
                    localStorage.removeItem("workspace_list");
                    localStorage.removeItem("workspaces_list");
                    navigate("/");
                  }}
                >
                  <NavLink className="nav-link" to="/">
                    <span>
                      <svg className="icon">
                        <use href="#icon_logout"></use>
                      </svg>
                    </span>
                    <strong>Logout</strong>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </aside>
      )}

      <div
        style={{ display: modelopen ? "block" : "none" }}
        ref={modalRef}
        className="custom-model w-[420px] bg-[#fff] fixed left-[15px] bottom-[20px] z-50 rounded-[15px] overflow-hidden shadow-lg shadow-[#00000040]"
      >
        <div className="flex items-center w-full p-4 bg-gradient-to-r from-[#097BA9] to-[#9B3785]">
          <div>
            <img
              src={window.location.origin + "/images/white-logo.png"}
              alt="logo"
            />
          </div>
          <div className="mx-auto">
            {(getPlanDetails().planId == 1 || getPlanDetails().planId == 2) && (
              <button
                type="button"
                className="py-1 font-semibold px-4 bg-[#fff] rounded-[20px] mix-blend-screen flex items-center"
                onClick={() => navigate("/subscription")}
              >
                Upgrade
                <svg className="icon text-[22px] ms-2">
                  <use href="#icon_arrow"></use>
                </svg>
              </button>
            )}
          </div>
          <div>
            <button
              type="button"
              className="text-[#fff] hover:text-[red]"
              onClick={() => setModelopen(false)}
            >
              <svg className="icon text-[32px]">
                <use href="#icon_close"></use>
              </svg>
            </button>
          </div>
        </div>
        <div className="p-4 w-full flex">
          <div className="w-[18%] px-2 flex flex-col">
            <Tooltip
              content="Add Workspace"
              placement="right"
              className="tooltip"
            >
              <button
                type="button"
                className="mb-3"
                onClick={() => {
                  if (!checkWorkspaceEnabled()) {
                    setpopupMessage("Add New Workspace");
                    setshowModal({
                      first: false,
                      second: true,
                      third: false,
                    });
                    return;
                  } else {
                    navigate("/add-workspace");
                  }
                }}
              >
                <div className="w-[40px] h-[40px] bg-[#7BFF78] text-[#fff] font-semibold text-[16px] rounded-full flex items-center justify-center hover:bg-[#097BA9]">
                  <svg className="icon text-[18px]">
                    <use href="#icon_plus"></use>
                  </svg>
                </div>
              </button>
            </Tooltip>
            <div className="max-h-[160px] overflow-auto">
              {getWorkspaceList()?.map((workspace, workspace_index) => (
                <Tooltip
                  content={workspace?.workspace?.workspace_name}
                  placement="right"
                  className="tooltip"
                  key={workspace_index}
                >
                  <button
                    type="button"
                    onClick={() => {
                      localStorage.setItem(
                        "selected_workspace",
                        JSON.stringify(workspace)
                      );
                      setselectedWorkspace(workspace);
                      setModelopen(false);
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: getBackgroundColor(
                          workspace?.workspace?.workspace_name?.charAt(0) || ""
                        ),
                      }}
                      className="w-[40px] h-[40px] bg-[#60CFFF] text-[#fff] font-semibold text-[16px] rounded-full flex items-center justify-center  mb-3"
                    >
                      {getLetters(workspace?.workspace?.workspace_name)}
                    </div>
                  </button>
                </Tooltip>
              ))}
            </div>
          </div>
          <div className="w-[41%] border-l border-r px-[12px]">
            <button
              type="button"
              className="flex items-center hover:text-[#1A71A7] whitespace-pre-wrap"
            >
              <div
                style={{
                  backgroundColor: getBackgroundColor(
                    JSON.parse(
                      localStorage.getItem("selected_workspace") || "{}"
                    )?.workspace?.workspace_name?.charAt(0) || ""
                  ),
                }}
                className="min-w-[40px] h-[40px] bg-[#FBC575] text-[#fff] font-semibold text-[16px] rounded-full flex items-center justify-center"
              >
                {getLetters(
                  JSON.parse(localStorage.getItem("selected_workspace") || "{}")
                    ?.workspace?.workspace_name || ""
                )}
              </div>
              <span className="ms-1 break-all">
                {JSON.parse(
                  localStorage.getItem("selected_workspace") || "{}"
                )?.workspace?.workspace_name?.toUpperCase()}
                <div>Workspace</div>
              </span>
            </button>
            <ul className="pt-2">
              <li className="pb-2">
                <button
                  type="button"
                  className="text-[#B3B3B3] hover:text-[#097BA9] flex items-center"
                  onClick={() => navigate("/account-settings")}
                >
                  <svg className="icon me-2 text-[22px]">
                    <use href="#icon_setting"></use>
                  </svg>
                  Settings
                </button>
              </li>
              <li className="pb-2">
                <button
                  type="button"
                  className="text-[#B3B3B3] hover:text-[#097BA9] flex items-center"
                  onClick={() => navigate("/member")}
                >
                  <svg className="icon me-2 text-[22px]">
                    <use href="#icon_member"></use>
                  </svg>
                  Members
                </button>
              </li>
              <li className="pb-2">
                <button
                  type="button"
                  className="text-[#B3B3B3] hover:text-[#097BA9] flex items-center"
                  onClick={() => navigate("/manage-space")}
                >
                  <svg className="icon me-2 text-[22px]">
                    <use href="#icon_space"></use>
                  </svg>
                  Spaces
                </button>
              </li>
              {/* <li className="pb-2">
                <button
                  type="button"
                  className="text-[#B3B3B3] hover:text-[#097BA9] flex items-center"
                >
                  <svg className="icon me-2 text-[22px]">
                    <use href="#icon_folder"></use>
                  </svg>
                  Folders
                </button>
              </li> */}
              <li>
                <button
                  type="button"
                  className="text-[#B3B3B3] hover:text-[#097BA9] flex items-center"
                  onClick={() => navigate("/billing")}
                >
                  <svg className="icon me-2 text-[22px]">
                    <use href="#icon_bill"></use>
                  </svg>
                  Billings
                </button>
              </li>
            </ul>
          </div>
          <div className="w-[41%] px-[12px]">
            <button
              type="button"
              className="flex items-center hover:text-[#1A71A7] whitespace-pre-wrap"
            >
              <div
                style={{
                  backgroundColor: getBackgroundColor(
                    JSON.parse(
                      localStorage.getItem("login_data")
                    )?.resp_user?.first_name?.charAt(0)
                  ),
                }}
                className="min-w-[40px] h-[40px] bg-[#FBC575] text-[#fff] font-semibold text-[16px] rounded-full flex items-center justify-center"
              >
                {getShortCutName(
                  loginDetails?.resp_user?.first_name,
                  loginDetails?.resp_user?.last_name
                )}
              </div>
              <span className="ms-1">
                {" "}
                {loginDetails?.resp_user?.first_name +
                  " " +
                  loginDetails?.resp_user?.last_name}
              </span>
            </button>
            <ul className="pt-2">
              <li className="pb-2">
                <button
                  type="button"
                  className="text-[#B3B3B3] hover:text-[#097BA9] flex items-center"
                  onClick={() => navigate("/my-settings")}
                >
                  <svg className="icon me-2 text-[22px]">
                    <use href="#icon_setting"></use>
                  </svg>
                  My Settings
                </button>
              </li>
              {/* <li className="pb-2">
                <button
                  type="button"
                  className="text-[#B3B3B3] hover:text-[#097BA9] flex items-center"
                >
                  <svg className="icon me-2 text-[22px]">
                    <use href="#icon_bell"></use>
                  </svg>
                  Notification
                </button>
              </li> */}
              <li className="pb-2">
                <button
                  type="button"
                  className="text-[#B3B3B3] hover:text-[#097BA9] flex items-center"
                  onClick={() => {
                    localStorage.removeItem("login_data");
                    localStorage.removeItem("login_token");
                    localStorage.removeItem("selected_workspace");
                    localStorage.removeItem("workspace_list");
                    localStorage.removeItem("workspaces_list");
                    navigate("/");
                  }}
                >
                  <svg className="icon me-2 text-[22px]">
                    <use href="#icon_logout"></use>
                  </svg>
                  Log Out
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* =====dropdown modal */}
      <ul
        className="dropdown-aside bg-[#fff] border p-2 shadow rounded text-gray-700 pt-1 text-[14px]"
        ref={sideMenuRef}
      >
        {selectedNestedItem?.level == "1" && (
          <li className="border-b">
            <button
              type="button"
              className="py-2 px-4 block whitespace-no-wrap hover:text-[#266AA6]"
              onClick={() => {
                setselectedFolderStructure({
                  type: "folder",
                  value: "",
                  title: "Folder Name",
                  placeholder: "Enter your folder name",
                  mainHeading: "Create Folder",
                  loading: false,
                  error: null,
                });
                setopenFolderCreateModal("dismissible");
                setsidemenuopen(false);
                closeMenuz();
              }}
            >
              <svg className="icon me-1">
                <use href="#icon_folder"></use>
              </svg>
              Folder
            </button>
          </li>
        )}
        {selectedNestedItem?.level != "3" && (
          <li className="border-b">
            <button
              type="button"
              className="py-2 px-4 block whitespace-no-wrap hover:text-[#266AA6]"
              onClick={() => {
                setselectedFolderStructure({
                  type: "file",
                  value: "",
                  title: "File Name",
                  placeholder: "Enter your file name",
                  mainHeading: "Create File",
                  loading: false,
                  error: null,
                });
                setopenFolderCreateModal("dismissible");
                setsidemenuopen(false);
                closeMenuz();
              }}
            >
              <svg className="icon me-1">
                <use href="#icon_doc"></use>
              </svg>
              Doc
            </button>
          </li>
        )}

        <li className="">
          <button
            type="button"
            className="py-2 px-4 block whitespace-no-wrap hover:text-[#266AA6]"
            onClick={() => {
              if (selectedNestedItem?.level == "1") {
                setselectedFolderStructure({
                  type: "space",
                  value: selectedNestedItem?.user_workspace_space?.space_name,
                  title: "Space Name",
                  placeholder: "Enter your space name",
                  mainHeading: "Update Space",
                  space_type: selectedNestedItem?.space_type,
                  loading: false,
                  error: null,
                });
                setopenFolderCreateModal("dismissible");
                setsidemenuopen(false);
                closeMenuz();
              } else if (
                selectedNestedItem?.level == "2" &&
                selectedNestedItem?.type == "folder"
              ) {
                setselectedFolderStructure({
                  type: "folder",
                  value: selectedNestedItem?.folder_name,
                  title: "Folder Name",
                  placeholder: "Enter your folder name",
                  mainHeading: "Update Folder",
                  loading: false,
                  error: null,
                });
                setopenFolderCreateModal("dismissible");
                setsidemenuopen(false);
                closeMenuz();
              } else if (
                selectedNestedItem?.level == "3" ||
                selectedNestedItem?.type == "file"
              ) {
                setselectedFolderStructure({
                  type: "file",
                  value: selectedNestedItem?.content_title || "",
                  title: "File Name",
                  placeholder: "Enter your file name",
                  mainHeading: "Update File",
                  loading: false,
                  error: null,
                });
                setopenFolderCreateModal("dismissible");
                setsidemenuopen(false);
                closeMenuz();
              }
            }}
          >
            <svg className="icon me-1">
              <use href="#icon_rename"></use>
            </svg>
            Rename
          </button>
        </li>
        {/* <li className="">
          <button
            type="button"
            className="py-2 px-4 block whitespace-no-wrap hover:text-[#266AA6]"
          >
            <svg className="icon me-1">
              <use href="#icon_duplicate"></use>
            </svg>
            Duplicate
          </button>
        </li> */}
        {/* <li className="">
          <button
            type="button"
            className="py-2 px-4 block whitespace-no-wrap hover:text-[#266AA6]"
          >
            <svg className="icon me-1">
              <use href="#icon_move"></use>
            </svg>
            Move
          </button>
        </li> */}
        {/* {selectedNestedItem?.level == "3" && (
          <li className="border-b">
            <button
              type="button"
              className="py-2 px-4 block whitespace-no-wrap hover:text-[#266AA6]"
            >
              <svg className="icon me-1">
                <use href="#icon_bookmark"></use>
              </svg>
              Add to Bookmark
            </button>
          </li>
        )} */}

        {/* <li className="">
          <button
            type="button"
            className="py-2 px-4 block whitespace-no-wrap hover:text-[#F1C248]"
          >
            <svg className="icon me-1">
              <use href="#icon_archive"></use>
            </svg>
            Archive
          </button>
        </li> */}
        <li className="">
          <button
            type="button"
            className="py-2 px-4 block whitespace-no-wrap hover:text-[#EF3D3D]"
            onClick={openModalHandlerDelete}
          >
            <svg className="icon me-1">
              <use href="#icon_delete"></use>
            </svg>
            Delete
          </button>
        </li>
      </ul>
      {/* Modal Invite */}
      <Modal
        root={documentBodyRef.current}
        dismissible
        show={openModal === "dismissible"}
        size="5xl"
        onClose={() => setOpenModal(undefined)}
        onSubmit={(e) => e.preventDefault()}
      >
        <div ref={allrefs?.totalTemplatesRef}>
          <Modal.Header className="capitalize py-2">
            <h6 className="font-bold text-[22px]">Templates</h6>
          </Modal.Header>
          <Modal.Body className="p-0 modal-aside">
            <div className="w-full flex">
              <div className="aside">
                <div className="flex items-center py-3 border-b mb-2 px-6 font-[500] text-[18px]">
                  <svg className="icon me-[12px]">
                    <use href="#icon_template"></use>
                  </svg>
                  All Templates
                </div>
                <ul>
                  {allTemplates.map((template, template_index) => (
                    <li
                      onClick={() => {
                        if (!checkByReplaceMain(template)) return;
                        setchoosedTemplate(template.title);
                      }}
                    >
                      <div
                        className="nav-link"
                        // to="/"
                        style={{
                          background:
                            choosedTemplate == template.title && "#e9f2ff",
                          color: choosedTemplate == template.title && "#097ba9",
                        }}
                      >
                        <span>
                          <svg className="icon">
                            <use href={template.icon}></use>
                          </svg>
                        </span>
                        <strong>{template.title}</strong>
                      </div>
                    </li>
                  ))}
                </ul>
                {/* <div className="flex items-center py-3 border-t py-4 px-6 font-[500] text-[18px]">
                <svg className="icon me-[12px]">
                  <use href="#icon_custom"></use>
                </svg>
                Custom
              </div> */}
              </div>
              <div className="w-full px-[40px] py-4 overflow-auto max-h-[450px]">
                <div className="">
                  <input
                    type="search"
                    autoFocus
                    className="w-full rounded-full mb-[20px] font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                    placeholder="Search Templates"
                    value={searchedInput}
                    onChange={(e) => {
                      setsearchedInput(e.target.value);
                    }}
                  />
                </div>

                <div class="flex flex-wrap -mx-6">
                  {templatesAndNavigation[choosedTemplate]
                    ?.filter((template) =>
                      template?.title
                        ?.toLowerCase()
                        .includes(searchedInput?.toLowerCase() || "")
                    )
                    ?.map((template, template_index) => (
                      <div
                        className="w-full md:w-1/2 px-6 mb-6 md:mb-0"
                        onClick={async () => {
                          if (!checkByReplace(template)) return;
                          if (template.navigation != "") {
                            if (
                              localStorage.getItem("template_selected") ===
                              "from_asidebar"
                            ) {
                              await callUpdateToFile(
                                {
                                  content_template: template?.title,
                                },
                                template
                              );
                            } else {
                              await callUpdateToFile(
                                {
                                  content_template: template?.title,
                                },
                                template
                              );
                            }
                            setselectedTemplate(template);
                          }
                        }}
                      >
                        <button
                          type="button"
                          // className="w-full mb-[20px] text-start p-[10px] rounded-[15px] bg-[#FAFBFC] border border-[#E9EBF0] ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 cursor-pointer hover:shadow-lg hover:shadow-[#E9F2FF] hover:border-[#097BA9] hover:text-[#097BA9] relative"
                          className={`w-full mb-[20px] text-start p-[10px] rounded-[15px] bg-[#FAFBFC] border border-[#E9EBF0] ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 cursor-pointer hover:shadow-lg hover:shadow-[#E9F2FF] hover:border-[#097BA9] hover:text-[#097BA9] relative ${
                            allrefs?.isGlowing ? "glowing-border" : ""
                          }`}
                        >
                          {template.modified_title ? (
                            <h5 className="pb-4 font-[500] text-[18px]">
                              {template?.modified_title}
                            </h5>
                          ) : (
                            <h5 className="pb-4 font-[500] text-[18px]">
                              {template?.title}
                            </h5>
                          )}
                          <p>{template?.description}</p>
                          <div
                            className={`template-popup ${
                              !checkByReplace(template) && "active"
                            }`}
                          >
                            <div className="pop-wrapper">
                              <div className="popup-inner">
                                <div className="bg-[#fff]">
                                  <p className="py-2 text-center text-[14px] font-[700] text-transparent bg-clip-text bg-gradient-to-r from-[#1A71A7] to-[#9D3784]">
                                    please upgrade to use this template
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </button>
                        {/* <button
                        type="button"
                        className="w-full mb-[20px] text-start p-[10px] rounded-[15px] bg-[#FAFBFC] border border-[#E9EBF0] ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 cursor-pointer hover:shadow-lg hover:shadow-[#E9F2FF] hover:border-[#097BA9] hover:text-[#097BA9] relative"
                      >
                        <h5 className="pb-4 font-[500] text-[18px] popup-inner">
                          Blog Sections
                        </h5>
                        <p>
                          Lorem ipsum dolor sit amet,consect etur adipiscing
                          elit.Sed nec dui at se m ultrices egestas
                        </p>
                        <div className="template-popup active">
                          <div className="pop-wrapper">
                            <div className="popup-inner">
                              <div className="bg-[#fff]">
                                <p className="py-2 text-center text-[14px] font-[700] text-transparent bg-clip-text bg-gradient-to-r from-[#1A71A7] to-[#9D3784]">
                                  please upgrade to use this template
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </button> */}
                      </div>
                    ))}
                </div>
                {/* <div className="flex">
                <button
                  type="button"
                  className="ms-auto flex items-center py-2 px-10 rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
                  // onClick={() => {
                  //   setOpenModal(undefined);
                  //   navigate(selectedTemplate?.navigation);
                  // }}
                >
                  <span>Create File</span>
                  <svg className="icon ms-2 text-[20px]">
                    <use href="#icon_arrow"></use>
                  </svg>
                </button>
              </div> */}
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        root={documentBodyRef.current}
        show={openFolderCreateModal === "dismissible"}
        size="lg"
        onClose={() => setopenFolderCreateModal(undefined)}
        style={{ zIndex: 1001 }}
      >
        <Modal.Header className="capitalize py-2">
          <h6 className="font-bold text-[24px]">
            {selectedFolderStructure.mainHeading}
          </h6>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="pb-3 pt-4">
            <label htmlFor="file">{selectedFolderStructure?.title}</label>
            <input
              type="text"
              name="file"
              value={selectedFolderStructure?.value}
              onChange={(e) => {
                setselectedFolderStructure({
                  ...selectedFolderStructure,
                  value: e.target.value,
                });
              }}
              className="mt-1 w-full rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
              placeholder={selectedFolderStructure?.placeholder}
            />
          </div>
          {selectedFolderStructure?.error && (
            <div className="text-red-700">{selectedFolderStructure?.error}</div>
          )}

          <div className="pt-4">
            <button
              type="button"
              className="w-full flex items-center text-center justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
              disabled={selectedFolderStructure?.loading}
              onClick={() => {
                if (
                  !selectedNestedItem ||
                  selectedFolderStructure?.mainHeading == "Update Space" ||
                  selectedFolderStructure?.mainHeading == "Create Space"
                ) {
                  submitWorkspace();
                } else if (
                  selectedFolderStructure?.mainHeading == "Create Folder" ||
                  selectedFolderStructure?.mainHeading == "Update Folder"
                ) {
                  submitWorkspaceFolder();
                } else if (
                  selectedFolderStructure?.mainHeading == "Create File"
                ) {
                  submitWorkspaceFolderFIle();
                } else if (
                  selectedFolderStructure?.mainHeading == "Update File"
                ) {
                  renameFileApi();
                }
              }}
            >
              {selectedFolderStructure?.loading ? (
                <div className="flex items-center">
                  <div>
                    {selectedFolderStructure?.mainHeading?.includes("Update")
                      ? "Updating"
                      : "Creating"}
                  </div>
                  <div>
                    <ThreeDotsLoader />
                  </div>
                </div>
              ) : (
                <>
                  {selectedFolderStructure?.mainHeading?.includes("Update")
                    ? "Update"
                    : "Create"}
                </>
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        root={documentBodyRef.current}
        dismissible
        show={renameChat === "dismissible"}
        size="lg"
        onClose={() => setrenameChat(undefined)}
        style={{ zIndex: 1001 }}
      >
        <Modal.Header className="capitalize py-2">
          <h6 className="font-bold text-[24px]">Rename</h6>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="pb-3 pt-4">
            <label htmlFor="file">Title</label>
            <input
              type="text"
              name="file"
              value={selectedChat?.title}
              onChange={(e) => {
                setselectedChat({
                  ...selectedChat,
                  title: e.target.value,
                });
              }}
              className="mt-1 w-full rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
              placeholder="Enter chat name"
            />
          </div>
          {selectedChat?.error && (
            <div className="text-red-700">{selectedChat?.error}</div>
          )}

          <div className="pt-4">
            <button
              type="button"
              className="w-full flex items-center text-center justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
              disabled={selectedChat?.loading}
              onClick={() => {
                renameChatApi();
              }}
            >
              {selectedChat?.loading ? (
                <div className="flex items-center">
                  <div>Saving</div>
                  <div>
                    <ThreeDotsLoader />
                  </div>
                </div>
              ) : (
                <>Save</>
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <DeletePopup
        openModal={openModalDelete}
        onClose={closeModalHandlerDelete}
        deleteFile={deleteFile}
        closeMenuz={closeMenuz}
        deleting={deleting}
      />

      <CreateNewSpaceModal
        selectedMemberList={selectedMemberList}
        setselectedMemberList={setselectedMemberList}
        openModalRemove={openModalRemove}
        privatepublic={privatepublic}
        setprivatepublic={setprivatepublic}
        handleCloseSpaceModal={handleCloseSpaceModal}
        selectedFolderStructure={selectedFolderStructure}
        setselectedFolderStructure={setselectedFolderStructure}
        membersList={membersList}
        submitWorkspace={submitWorkspace}
        setpopupMessage={setpopupMessage}
        setshowModal={setshowModal}
      />
      <Modal
        root={documentBodyRef.current}
        dismissible
        show={openModalMembers === "dismissible"}
        size="lg"
        onClose={() => setopenModalMembers(undefined)}
      >
        <Modal.Header className="capitalize py-2">
          <h6 className="font-bold text-[24px]">Invite member</h6>
          <p className="font-[400] text-[16px]">
            you are inviting members to{" "}
            {selectedWorkspace?.workspace?.workspace_name?.toUpperCase()}
          </p>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="flex justify-center">
            <img src="images/invite.png" className="w-[280px]" alt="..." />
          </div>
          <div className="pb-3">
            <label for="email">Email Address</label>
            <div
              className={`flex flex-wrap gap-2 items-center ${
                emails?.length > 0 && "py-2"
              }`}
            >
              {emails.map((email, index) => (
                <div
                  key={index}
                  className="border rounded-md p-1 flex items-center bg-gray-200"
                >
                  <span>{email}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveEmail(index)}
                    className="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                  >
                    &#x2715;
                  </button>
                </div>
              ))}
            </div>
            <input
              type="email"
              name="email"
              value={currentEmail}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              onBlur={() => {
                if (currentEmail == "") {
                  setIsValidEmail(true);
                }
              }}
              className="mt-1 w-full rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
              placeholder="Invite team members"
              required
            />
            {!isValidEmail && (
              <p className="text-red-500 text-sm mt-1">
                Please enter a valid email address
              </p>
            )}
            {currentEmail !== "" && isValidEmail && (
              <p
                className="border rounded-md pt-1 pb-1 pl-2 pr-2 inline-block bg-gray-200 cursor-pointer mt-1"
                onClick={inviteEmail}
              >
                Invite {currentEmail}
              </p>
            )}
          </div>
          <div>
            <label for="role">Roles</label>
            <select
              className="mt-1 w-full rounded-full px-3 py-2 bg-[#FAFBFC] border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block sm:text-sm focus:ring-1"
              name="cars"
              id="point_of_view"
              value={selectedUserTypeId}
              onChange={(e) => setselectedUserTypeId(e.target.value)}
              required
            >
              <option value="">Select Role</option>
              {membersListTotal?.membertypes?.map((item) => (
                <option value={item?.user_type_id}>{item?.member_type}</option>
              ))}
            </select>
          </div>
          <div className="flex pt-4">
            <div className="ms-auto">
              <button
                type="buttonn"
                className="border border-[#2A2A2A] rounded-full px-8 py-2 me-6 hover:text-[red] hover:border-[red]"
                onClick={() => {
                  setEmails([]);
                  setCurrentEmail("");
                  setIsValidEmail(true);
                  setopenModalMembers(undefined);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="bg-[#85D977] hover:bg-[#55b943] rounded-full text-[#fff] px-8 py-2"
                onClick={() => {
                  if (emails.length == 0 || selectedUserTypeId == "") return;
                  if (emails?.length > 0) {
                    submitWorkspaceTeamMembers();
                  }
                }}
              >
                {loading ? (
                  <div className="flex items-center">
                    <div>Inviting</div>
                    <div>
                      <ThreeDotsLoader />
                    </div>
                  </div>
                ) : (
                  "Invite"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <PopupModals
        showModal={showModal}
        setshowModal={setshowModal}
        popupMessage={popupMessage}
      />
      <ReferalModalPopUp
        afRefCodeFn={afRefCodeFn}
        documentBodyRef={documentBodyRef}
        loading={loadingReferal}
        setloading={setloadingReferal}
        openModalReferal={openModalReferal}
        setopenModalReferal={setopenModalReferal}
        setsignUpdata={setsignUpdata}
        signUpdata={signUpdata}
      />
    </>
  );
});
export default AsideBar;
