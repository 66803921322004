/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import NavBar2 from "../components/NavBar2";
import useWebsocketHook from "../hooks/post-websocket-data";
import BlogComponent from "../components/BlogComponent";
import ThreeDotsLoader from "../components/ThreeDotsLoader";
import TextEditor from "../components/TextEditor";
import UserContext from "../context/UserContext";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import useCommonApi from "../hooks/common-apis";
import useCommonFunctions from "../hooks/common-functions";

const useWebSocketHandler = (generateBlogFnNew, outlinesArray) => {
  const context = useContext(UserContext);
  const { setsavingFileData } = context;
  const currentWsRef = useRef(null);
  const isUnmountedRef = useRef(false);

  useEffect(() => {
    return () => {
      isUnmountedRef.current = true;
      if (
        currentWsRef.current &&
        currentWsRef.current.readyState === WebSocket.OPEN
      ) {
        const stopMessage = JSON.stringify({ stop: "True" });
        currentWsRef.current.send(stopMessage);
        // currentWsRef.current.close();
        setsavingFileData(true);
        currentWsRef.current = null;
      }
    };
  }, []);

  const wrappedGenerateBlogFnNew = (...args) => {
    isUnmountedRef.current = false;
    generateBlogFnNew(...args, currentWsRef, isUnmountedRef);
  };

  return wrappedGenerateBlogFnNew;
};

function ThreeStepBlog() {
  const context = useContext(UserContext);
  const {
    selectedWorkspace,
    setselectedWorkspace,
    selectedTemplate,
    setselectedTemplate,
    setselectedFile,
    setselectedContentSettings,
    contentEditableRef,
    setsavingFileData,
  } = context;
  const selectedContentSettings = JSON.parse(
    localStorage.getItem("selectedContentSettings")
  );
  const { getRecentFiles, callUpdateToFileApi } = useCommonApi();
  const { getPlanDetails, getDescription } = useCommonFunctions();
  const [inputBoxDescription, setinputBoxDescription] = useState("Description");
  const [fullscreenClicked, setfullscreenClicked] = useState(false);
  const [inputData, setInputData] = useState({
    title: "",
    type: "Type 2",
    no_of_outlines: "3",
    add_info: "",
    target_audience: "",
    conclusion: "",
  });
  const [selectedTone, setselectedTone] = useState("");
  const [onestepWS, setonestepWS] = useState(null);
  const [wsOutline, setWswsOutline] = useState(null);

  const [stepTwoStringWords, setstepTwoStringWords] = useState("");
  const [dropdownopen, setdropdownopen] = useState(false);
  const [stringOutput, setstringOutput] = useState([]);
  const [selectedTitle, setselectedTitle] = useState("");
  const [loaderStepTwo, setloaderStepTwo] = useState(false);
  const [selectedInputData, setselectedInputData] = useState({});
  const [stringOutputOutlines, setstringOutputOutlines] = useState("");
  const [outlinesArray, setoutlinesArray] = useState([]);
  const [outlinesCompleted, setoutlinesCompleted] = useState(false);
  // Type 1
  const [outlineDictionaryStructure, setoutlineDictionaryStructure] = useState(
    {}
  );
  const [number_of_words, setnumber_of_words] = useState("");

  const [wordCounts, setWordCounts] = useState({});
  // Type 2
  const [type2Outlines, settype2Outlines] = useState([]);
  const [type2wordCounts, settype2WordCounts] = useState([]);
  const location = useLocation();
  const [outlinesTotalModal11, setoutlinesTotalModal1] = useState([
    {
      key: "Introduction",
      keywords: ["vision", "exploration", "English language"],
      percentage: "10",
      number_of_words: "100",
    },
    {
      key: "Importance of Vision in Exploration",
      keywords: ["vision", "goal-setting", "direction", "inspiration"],
      percentage: "20",
      number_of_words: "200",
    },
    {
      key: "Role of Thoughtful Style in Communication",
      keywords: ["thoughtful style", "communication", "clarity", "empathy"],
      percentage: "30",
      number_of_words: "300",
    },
    {
      key: "Unraveling the Connection Between Vision, Exploration, and Thoughtful Style",
      keywords: [
        "vision",
        "exploration",
        "thoughtful style",
        "communication",
        "creativity",
      ],
      percentage: "30",
      number_of_words: "300",
    },
    {
      key: "Conclusion",
      keywords: ["reflection", "integration", "action", "impact"],
      percentage: "10",
      number_of_words: "100",
    },
  ]);
  const [outlinesTotal, setoutlinesTotal] = useState({});
  const [outlinesTotalModal, setoutlinesTotalModal] = useState([]);
  const scrollLeftRef = useRef();
  // console.log(outlinesTotalModal, "outlinesTotalModal");

  const handleType2OutlineChange = (index, value) => {
    const updatedOutlines = [...type2Outlines];
    updatedOutlines[index] = value;
    settype2Outlines(updatedOutlines);
  };

  const handleType2WordCountChange = (index, value) => {
    const updatedWordCounts = [...type2wordCounts];
    updatedWordCounts[index] = value;
    settype2WordCounts(updatedWordCounts);
  };

  const handleDeleteOutline = (index) => {
    const updatedOutlines = [...type2Outlines];
    updatedOutlines.splice(index, 1);
    settype2Outlines(updatedOutlines);

    const updatedWordCounts = [...type2wordCounts];
    updatedWordCounts.splice(index, 1);
    settype2WordCounts(updatedWordCounts);
  };

  const [inputValues, setInputValues] = useState({});
  const [loading, setloading] = useState(false);
  const [loadingStep3, setloadingStep3] = useState(false);
  const [allOutlinesArray, setallOutlinesArray] = useState([
    "Contemplating the Ethical Implications of AI\n",
    "\nExploring the Potential Risks and Benefits of AI",
  ]);
  const [finalOutputString, setfinalOutputString] = useState("");

  const handleOutlineChange = (section, index, value) => {
    const updatedOutline = { ...outlineDictionaryStructure };
    updatedOutline[section][index] = value;
    setoutlineDictionaryStructure(updatedOutline);
  };

  const handleWordCountChange = (section, index, value) => {
    const updatedWordCounts = { ...wordCounts };
    updatedWordCounts[section][index] = value;
    setWordCounts(updatedWordCounts);
  };
  const handleDelete = (section, index) => {
    const updatedOutline = { ...outlineDictionaryStructure };
    updatedOutline[section].splice(index, 1);
    if (updatedOutline[section]?.length == 0) {
      delete updatedOutline[section];
    }
    setoutlineDictionaryStructure(updatedOutline);

    const updatedWordCounts = { ...wordCounts };
    updatedWordCounts[section].splice(index, 1);
    setWordCounts(updatedWordCounts);
  };

  const [editorDisplay, seteditorDisplay] = useState(false);
  const { postWebsocketDetails, closeFunction, loader, stringWords, ws } =
    useWebsocketHook();
  const navigate = useNavigate();
  const onchangeDescription = (e) => {
    setinputBoxDescription(e.target.value);
  };
  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.id]: e.target.value });
  };
  const setselectedtoneFn = (tone) => {
    setselectedTone(tone);
  };
  console.log(outlinesTotal, "outlinesTotal");
  const postWebsocketDetailsStepTwoOutlines = (replace, parseAttempts = 0) => {
    if (wsOutline) {
      wsOutline.close(); // Close the existing WebSocket connection if it exists
      setWswsOutline(null); // Reset the WebSocket state
    }

    // const newWs = new WebSocket(
    //   `${process.env.REACT_APP_BASE_URL}/get_structured_outline`
    // );
    const newWs = new WebSocket(
      `${process.env.REACT_APP_BASE_URL}/get_structured_outline_2`
    );

    setWswsOutline(newWs);
    const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
    let selected_file_data = {
      content_id: selectedFile?.content_id || selectedFile?.id || "",
      space_id: selectedFile?.space_id || "",
      folder_id: selectedFile?.folder_id || "",
    };

    // let parseAttempts = 0;

    newWs.onopen = function open() {
      let data = {
        no_of_outlines: inputData?.no_of_outlines,
        title: inputData.title?.replace(/\d+\./g, ""),
        type: "Type 2",
        tone: selectedInputData?.tone,
        language: selectedInputData?.language,
        add_info: inputData?.add_info,
        keywords:
          selectedInputData?.keywords && selectedInputData?.keywords?.length > 0
            ? JSON.stringify(selectedInputData?.keywords?.split(","))
            : "[]",
        audience: inputData?.target_audience,
        conclusion: inputData?.conclusion,
      };
      if (getPlanDetails().planId == 3) {
        let login_data = JSON.parse(
          localStorage.getItem("login_data")
        )?.resp_user;
        let model_id = login_data?.model_id;
        if (model_id) {
          data["model"] = model_id;
        } else {
          data["model"] = "model_3";
        }
      }
      let new_data = { ...data, token: localStorage.getItem("login_token") };
      const dataToSend = JSON.stringify(new_data);
      newWs.send(dataToSend);
    };

    newWs.onmessage = function incoming(data) {
      // console.log("received", data.data);
      const scrollAmount = scrollLeftRef.current.offsetWidth / 2;
      const currentScrollLeft = scrollLeftRef.current.scrollLeft;
      const targetScrollLeft = currentScrollLeft + scrollAmount;

      scrollLeftRef.current.scrollTo({
        left: targetScrollLeft,
        behavior: "smooth", // This enables smooth scrolling
      });

      const word = data.data;
      let outlinesTotalObj = {};
      if (
        word?.charAt(0) === "{" &&
        word.charAt(word.length - 1) === "}" &&
        word?.includes("query_word_count")
      ) {
      } else {
        let parsed = JSON.parse(word);
        outlinesTotalObj = parsed;
      }
      if (
        word?.charAt(0) === "{" &&
        word.charAt(word.length - 1) === "}" &&
        word?.includes("query_word_count")
      ) {
        setoutlinesCompleted(true);
        const correctedJsonString = word.replace(/'/g, '"');
        let selectedFileContentSettings = { ...selectedFile?.content_settings };
        selectedFileContentSettings["content_title"] =
          selectedFile?.content_title;
        selectedFileContentSettings["content_template"] =
          selectedFile?.content_template;
        console.log(outlinesTotalObj, "outlinesTotalObj");

        callUpdateToFileApi(
          selectedFileContentSettings,
          contentEditableRef?.current?.innerHTML || "",
          JSON.parse(correctedJsonString),
          selected_file_data,
          selectedFile,
        );
      } else {
        // setstringOutputOutlines((prev) => (prev += word));
        try {
          console.log(word, "word...");
          let parsed = JSON.parse(word);
          // Proceed with parsed data
          setoutlinesTotal(parsed);
          const convertedOutlines = Object.entries(parsed || {}).map(
            ([key, value]) => {
              const keywords = value[0];
              const { percentage } = value[1];
              return {
                key,
                keywords,
                percentage,
                number_of_words: Math.round(
                  (parseInt(percentage || 0) / 100) *
                    parseInt(number_of_words || 0)
                ),
              };
            }
          );

          setoutlinesTotalModal(convertedOutlines || {});
          // printOutlines(parsed);
          console.log(parsed, "parsed on outlines");
        } catch (error) {
          // Retry parsing once more
          console.log(error);
          if (parseAttempts < 1) {
            parseAttempts++;
            // postWebsocketDetailsStepTwoOutlines(replace);
            newWs?.close();
            if (wsOutline) {
              wsOutline.close(); // Close the existing WebSocket connection if it exists
              setWswsOutline(null); // Reset the WebSocket state
            }
            postWebsocketDetailsStepTwoOutlines(replace, parseAttempts + 1);
            console.error("Error parsing data, retrying once more...");
            return;
          } else {
            console.error("Failed to parse data after retrying twice:", error);
            // Log the error to the console or handle it as needed
          }
        }
      }
    };

    newWs.onclose = () => {
      setloading(false);
      console.log("closed");
    };

    newWs.onerror = (error) => {
      setloading(false);
      console.error("WebSocket error:", error);
    };
  };
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentOutlineIndex, setCurrentOutlineIndex] = useState(0);
  const [currentKeyIndex, setCurrentKeyIndex] = useState(0);
  const [fullText, setFullText] = useState("");

  useEffect(() => {
    if (outlinesCompleted && Object.keys(outlinesTotal || {}).length > 0) {
      const outlineKeys = Object.keys(outlinesTotal || {});
      if (currentIndex < outlineKeys.length) {
        const outlineKey = outlineKeys[currentIndex];
        const keyLength = outlineKey.length;
        const interval = setInterval(() => {
          if (currentKeyIndex == keyLength) {
            setloading(false);
          }
          if (currentKeyIndex < keyLength) {
            setCurrentText(
              (prevText) => prevText + outlineKey.charAt(currentKeyIndex)
            );
            setCurrentKeyIndex((prevIndex) => prevIndex + 1);
          } else {
            clearInterval(interval);
            setFullText(
              (prevFullText) => prevFullText + currentText + `<br/><br/>`
            );
            setCurrentText("");
            setCurrentIndex((prevIndex) => prevIndex + 1);
            setCurrentKeyIndex(0);
          }
        }, 50); // Adjust the interval duration as needed

        return () => clearInterval(interval);
      }
    }
  }, [currentIndex, currentKeyIndex, outlinesCompleted, outlinesTotal]);

  const getInputData = (data) => {
    setselectedInputData(data);
  };
  const modalToggle = (id) => {
    document.getElementById(id).classList.toggle("show");
  };
  const getItemSplit = (item) => {
    let splitOutline = item?.split("&&");
    return { title: splitOutline[0], outlines: splitOutline?.slice(1) };
  };
  console.log(outlinesTotal, "outlinesTotal");

  useEffect(() => {
    if (stringWords == "" && !loading) {
      setstringOutputOutlines("");
      setoutlinesArray((prevOutlinesArray) => []);
      setallOutlinesArray((prevAllOutlinesArray) => []);
      setoutlineDictionaryStructure({});
      settype2Outlines([]);
      setoutlinesCompleted(false);
      setfinalOutputString("");
      return;
    }
    let replace = stringWords.split("<br/>");
    let replace2 = replace?.filter((item) => item?.trim() != "");

    setstringOutput(replace2);
    // setfinalOutputString(selectedFile?.contents)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringWords]);
  useEffect(() => {
    // setstringOutput(["Hello World"]);
    // setoutlinesTotal({});
    //   setoutlinesTotal({
    //     "1. Introduction to Vision AI": [
    //         [
    //             "vision AI",
    //             "visual recognition",
    //             "machine learning"
    //         ],
    //         {
    //             "percentage": "24.0"
    //         }
    //     ],
    //     "2. Applications of Vision AI": [
    //         [
    //             "autonomous vehicles",
    //             "healthcare",
    //             "retail",
    //             "manufacturing"
    //         ],
    //         {
    //             "percentage": "38.0"
    //         }
    //     ],
    //     "3. The Future of Vision AI": [
    //         [
    //             "innovation",
    //             "ethical considerations",
    //             "market growth"
    //         ],
    //         {
    //             "percentage": "38.0"
    //         }
    //     ]
    // })
  }, []);

  useEffect(() => {
    if (stringOutputOutlines == "") {
      return;
    }

    let replace;
    if (inputData.type === "Type 1") {
      replace = stringOutputOutlines.split("####");
    } else {
      replace = stringOutputOutlines.split("\n");
    }
    // let replace = stringOutputOutlines.split("<br/>");
    let replace2 = replace?.filter((item) => item?.trim() != "");

    if (inputData.type == "Type 2") {
      settype2Outlines(replace2);
    } else {
      setoutlinesArray(replace2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringOutputOutlines]);

  const generateBlogFnNew = (outlines_array, currentWsRef, isUnmountedRef) => {
    const maxScrollLeft =
      scrollLeftRef.current.scrollWidth - scrollLeftRef.current.clientWidth;

    scrollLeftRef.current.scrollTo({
      left: maxScrollLeft,
      behavior: "smooth",
    });

    function sendOutlinesSequentially(outlines, index = 0, string) {
      let string2 = string;
      if (index == 0) {
        setloadingStep3(true);
      }
      if (index >= outlines.length || isUnmountedRef.current) {
        console.log("All outlines sent successfully or component unmounted.");
        return Promise.resolve();
      }
      const outline = outlines[index]?.key;
      const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
      let selected_file_data = {
        content_id: selectedFile?.content_id || selectedFile?.id || "",
        space_id: selectedFile?.space_id || "",
        folder_id: selectedFile?.folder_id || "",
      };

      const getQueryParams = () => {
        const queryParams = new URLSearchParams(location.search);
        const temp = queryParams.get("temp");
        if (temp) {
          return parseFloat(temp);
        } else {
          return 0.4;
        }
      };

      let data = {
        title: inputData.title,
        outline: outlines_array[index]?.key,
        words: outlines_array[index]?.number_of_words?.toString() || "0",
        tone: selectedInputData?.tone,
        keywords: JSON.stringify(outlines_array[index]?.keywords || []),
        all_outlines: JSON.stringify(outlinesTotal || {}),
        add_info: inputData?.add_info,
        temprature: getQueryParams(),
      };

      return new Promise((resolve, reject) => {
        if (currentWsRef.current) {
          currentWsRef.current.close();
          currentWsRef.current = null;
        }
        const newWs = new WebSocket(
          `${process.env.REACT_APP_BASE_URL}/outline_blog_generate_2`
        );
        currentWsRef.current = newWs;
        setonestepWS(newWs);

        newWs.onopen = function open() {
          let new_data = {
            ...data,
            token: localStorage.getItem("login_token"),
          };
          if (getPlanDetails().planId == 3) {
            let login_data = JSON.parse(
              localStorage.getItem("login_data")
            )?.resp_user;
            let model_id = login_data?.model_id;
            if (model_id) {
              new_data["model"] = model_id;
            } else {
              new_data["model"] = "model_3";
            }
          }
          if (index === 0) {
            string2 += `\n\n ### ${inputData.title} \n\n`;
            let selectedTitleReplace = inputData.title.replace(/\d\.?/g, "");
            setfinalOutputString(`\n\n ### ${selectedTitleReplace} \n\n`);
            setfinalOutputString((prev) => (prev += "\n\n"));
          }
          string2 += `\n\n ### ${outline} \n\n`;
          setfinalOutputString((prev) => (prev += `\n ### ${outline} \n`));
          setfinalOutputString((prev) => (prev += "\n\n"));
          const dataToSend = JSON.stringify(new_data);
          newWs.send(dataToSend);
        };

        newWs.onmessage = function incoming(data) {
          console.log("received", data.data);

          const word = data.data;
          if (word?.charAt(0) == "{" && word.charAt(word.length - 1) == "}") {
            newWs.close();
            setfinalOutputString((prev) => (prev += "\n\n"));
            const correctedJsonString = word.replace(/'/g, '"');
            let data = {};
            data["content_title"] = selectedFile?.content_title || "";
            data["content_template"] = selectedFile?.content_template || "";
            data = { ...data, ...selectedFile?.content_settings };
            if (index == outlines?.length - 1) {
              setloadingStep3(false);
              if (contentEditableRef && contentEditableRef.current) {
                contentEditableRef.current.style.border = "2px solid #ccc";
              }
              callUpdateToFileApi(
                data,
                string2,
                JSON.parse(correctedJsonString),
                selected_file_data,
                selectedFile,
                inputData.title,
                outlinesTotal
              );
            } else {
              callUpdateToFileApi(
                data,
                string2,
                JSON.parse(correctedJsonString),
                selected_file_data,
                selectedFile,
                inputData.title,
                outlinesTotal
              );
            }
            resolve();
          } else {
            string2 += word;
            seteditorDisplay(true);
            setfinalOutputString((prev) => (prev += word));
          }
        };

        newWs.onclose = () => {
          // setloadingStep3(false);
          console.log("closed");
        };

        newWs.onerror = (error) => {
          console.error("WebSocket error:", error);
          reject(error);

          setloadingStep3(false);
        };
      }).then(() => {
        return sendOutlinesSequentially(outlines, index + 1, string2);
      });
    }

    let string = "";
    setfinalOutputString("");

    sendOutlinesSequentially(outlines_array, 0, string)
      .then(() => {
        setloading(false);
        console.log("All outlines sent successfully.");
        setloadingStep3(false);
      })
      .catch((error) => {
        setloading(false);
        console.error("Error sending outlines:", error);
      });
  };
  const selectedFile = JSON.parse(localStorage.getItem("selected_file"));
  useEffect(() => {
    if (selectedFile && selectedFile?.contents) {
      setfinalOutputString(selectedFile?.contents);
      setoutlinesTotal(selectedFile?.outline_text);
      setoutlinesCompleted(true);
      setstringOutput([selectedFile?.blog_step]);
    }
  }, []);
  console.log(outlinesTotal, "outlinesTotal");
  useEffect(() => {
    return () => {
      if (wsOutline && wsOutline.readyState === WebSocket.OPEN) {
        const dataToSend = JSON.stringify({
          stop: "True",
        });
        wsOutline.send(dataToSend);
        setsavingFileData(true);
        // wsOutline.close(); // Close the existing WebSocket connection if it exists
      }
    };
  }, [wsOutline]);
  useEffect(() => {
    return () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        closeFunction(); // Close the existing WebSocket connection if it exists
      }
    };
  }, [ws]);

  // console.log(outlinesArray, "outlines array");
  // console.log(finalOutputString, "final output string");
  // console.log(type2Outlines, "type2Outlines");
  // console.log(allOutlinesArray, "all outlines array");
  // console.log(outlineDictionaryStructure, "outline dictuonary s.tructire");

  const wrappedGenerateBlogFnNew = useWebSocketHandler(
    generateBlogFnNew,
    outlinesArray
  );

  return (
    <div className="wrapper">
      <div
        class="w-full flex  -mx-4 px-1 overflow-auto"
        id="div2"
        ref={scrollLeftRef}
      >
        <NavBar2
          title="Three Step Blog"
          description={getDescription("Blog", "Three Step Blog")}
        />

        <div
          className="min-w-[50%] px-4 border-r"
          style={{
            height: "calc(100vh - 120px)",
            display: !fullscreenClicked ? "block" : "none",
          }}
        >
          <BlogComponent
            onchangeDescription={onchangeDescription}
            from="Three Step Blog"
            mainTitle="Three Step Blog"
            loader={loader}
            postWebsocketDetails={postWebsocketDetails}
            closeFunction={closeFunction}
            blog_topic
            keyword_include
            getInputData={getInputData}
            setselectedtoneFn={setselectedtoneFn}
            scrollLeftRef={scrollLeftRef}
          />
        </div>

        <div
          className={`min-w-[50%]  px-4 border-r`}
          style={{ height: "calc(100vh - 120px)" }}
        >
          <div className=" h-full overflow-auto pb-2">
            <div className="w-full flex justify-between items-center">
              <h2 className="font-bold text-[20px]">Blog Title</h2>
            </div>
            {stringOutput?.map((item, index) => (
              <>
                <div
                  className="pt-3"
                  onClick={() => {
                    if (loader) {
                      return;
                    }
                    if (selectedTitle == item) {
                      setselectedTitle("");
                      setInputData({ ...inputData, title: "" });
                      return;
                    }
                    setselectedTitle(item);
                    setInputData({ ...inputData, title: item });
                  }}
                >
                  <button
                    type="button"
                    className="w-full text-start block p-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                  >
                    <div className="flex items-center">
                      <h6 className="mb-2 text-[20px] font-bold tracking-tight text-gray-900 dark:text-white">
                        {item}
                      </h6>
                      <div className="ms-auto ">
                        {!dropdownopen && (
                          <div>
                            <svg className="icon text-[12px] ">
                              <use href="#icon_arrowdown"></use>
                            </svg>
                          </div>
                        )}
                        {dropdownopen && (
                          <div>
                            <svg className="icon text-[12px]">
                              <use href="#icon_arrowup"></use>
                            </svg>
                          </div>
                        )}
                      </div>
                    </div>
                  </button>
                </div>
                {selectedTitle == item && (
                  <>
                    <div className="py-3">
                      <label
                        class="block mb-2 font-semibold text-[18px]"
                        htmlFor="topic"
                      >
                        Edit title
                      </label>
                      {/* <input
                        type="text"
                        id="title"
                        className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                        placeholder="Edit Title"
                        disabled={loading}
                        value={inputData.title}
                        onChange={handleChange}
                        required
                      /> */}
                      <textarea
                        className="w-[98%] flex items-center ml-1 rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                        id="title"
                        name="msgarea"
                        placeholder="Enter Title"
                        onChange={handleChange}
                        value={inputData.title}
                        required
                      ></textarea>
                    </div>
                    <div className="pb-3">
                      <label
                        class="block mb-2 font-semibold text-[18px]"
                        htmlFor="target_audience"
                      >
                        Number of words
                      </label>
                      <input
                        type="number"
                        id="target_audience"
                        className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                        placeholder="Enter Number of words"
                        value={number_of_words}
                        onChange={(e) => {
                          setnumber_of_words(e.target.value);
                        }}
                        required
                      />
                      <div className="pb-3 pt-2">
                        <label
                          class="block mb-2 font-semibold text-[18px]"
                          htmlFor="target_audience"
                        >
                          Target Audience
                        </label>
                        <input
                          type="text"
                          id="target_audience"
                          className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                          placeholder="Enter Target Audience"
                          value={inputData.target_audience}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="pb-3">
                        <label
                          class="block mb-2 font-semibold text-[18px]"
                          htmlFor="add_info"
                        >
                          Additional Information
                        </label>
                        <textarea
                          className="w-full rounded-[20px] font-[400] min-h-[120px] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                          id="add_info"
                          name="msgarea"
                          placeholder="Enter here."
                          onChange={handleChange}
                          value={inputData.add_info}
                          required
                        ></textarea>
                      </div>
                      <div className="pb-3">
                        <label
                          class="block mb-2 font-semibold text-[18px]"
                          htmlFor="add_info"
                        >
                          Conclusion
                        </label>
                        <div className="flex items-center space-x-4 px-1">
                          <div>
                            <input
                              type="radio"
                              id="conclusionYes"
                              name="conclusion"
                              value="Yes"
                              onChange={(e) => {
                                setInputData({
                                  ...inputData,
                                  conclusion: "Yes",
                                });
                              }}
                              checked={inputData.conclusion === "Yes"}
                            />
                            <label htmlFor="conclusionYes" className="ml-2">
                              Yes
                            </label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="conclusionNo"
                              name="conclusion"
                              value="No"
                              onChange={(e) => {
                                setInputData({
                                  ...inputData,
                                  conclusion: "No",
                                });
                              }}
                              checked={inputData.conclusion === "No"}
                            />
                            <label htmlFor="conclusionNo" className="ml-2">
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pb-3">
                      <label
                        class="block mb-2 font-semibold text-[18px]"
                        for="no_of_outlines"
                      >
                        Number of Outlines
                      </label>
                      <select
                        className="w-full rounded-full font-[400] text-[16px] border border-[#E9EBF0] bg-[#FAFBFC]"
                        name="lang"
                        id="no_of_outlines"
                        disabled={loading}
                        value={inputData.no_of_outlines}
                        required
                        onChange={handleChange}
                      >
                        <option value="">Choose No of Outlines</option>
                        {/* <option value="1">1</option> */}
                        {/* <option value="2">2</option> */}
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                      </select>
                    </div>
                    <button
                      type="submit"
                      // disabled={loading}
                      className="w-full flex justify-between items-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
                      onClick={() => {
                        if (
                          onestepWS &&
                          onestepWS.readyState === WebSocket.OPEN
                        ) {
                          onestepWS?.close();
                          setonestepWS(null);
                        }
                        if (
                          wsOutline &&
                          wsOutline.readyState === WebSocket.OPEN
                        ) {
                          wsOutline?.close();
                          setWswsOutline(null);
                        }
                        setstringOutputOutlines("");
                        setoutlinesArray((prevOutlinesArray) => []);
                        setallOutlinesArray((prevAllOutlinesArray) => []);
                        setoutlineDictionaryStructure({});
                        settype2Outlines([]);
                        setoutlinesCompleted(false);
                        // setfinalOutputString("");
                        setloading(true);
                        setoutlinesTotal({});
                        setoutlinesCompleted(false);
                        setCurrentIndex(0);
                        setCurrentKeyIndex(0);
                        setCurrentText("");
                        setFullText("");
                        postWebsocketDetailsStepTwoOutlines();

                        // document.getElementById("div2").scrollLeft += 100;
                      }}
                    >
                      <div></div>
                      <div>Generate Step Two (Outlines)</div>
                      <div className="">
                        {/* <span>
                          <svg className="icon ms-2 text-[20px]">
                            <use href="#icon_arrow"></use>
                          </svg>
                        </span> */}
                        {loading && <ThreeDotsLoader />}
                      </div>
                    </button>
                  </>
                )}
              </>
            ))}
          </div>
        </div>
        <div className="min-w-[50%] px-4 border-r">
          <div className="w-full flex justify-between items-center">
            <h2 className="font-bold text-[20px]">Outlines</h2>
          </div>

          <div>
            {inputData.type == "Type 1" && outlinesArray?.length > 0 && (
              <div>
                {outlinesArray.map((item, index) => (
                  <div className="text-[18px] py-2" key={index}>
                    <div className="font-bold">{getItemSplit(item)?.title}</div>
                    <div>
                      {getItemSplit(item)?.outlines.map((item, index) => (
                        <div className=" text-[18px] py-1" key={index}>
                          {item}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
            {inputData.type == "Type 2" &&
              Object.keys(outlinesTotal || {}).length > 0 && (
                <div>
                  {/* {Object.keys(outlinesTotal||{}).map((item, index) => (
                  <div className="font-bold text-[18px] py-2" key={index}>
                    {item}
                  </div>
                ))} */}

                  <div
                    className="font-bold text-[18px] py-2"
                    dangerouslySetInnerHTML={{ __html: fullText + currentText }}
                  />
                  {/* {fullText + currentText} */}
                </div>
              )}
          </div>
          <button
            type="submit"
            disabled={loading}
            className="w-full mt-2 flex justify-between items-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
            onClick={() => {
              if (inputData.type == "Type 1") {
                let allOutlines = [];
                let outlineDictionary = {};
                for (let i = 0; i < outlinesArray.length; i++) {
                  let element = outlinesArray[i];

                  let elementArray = element.split("&&");
                  for (let i = 0; i < elementArray.length; i++) {
                    elementArray[i] = elementArray[i]?.trim();
                  }
                  let sliced = elementArray?.slice(1);
                  if (!outlineDictionary[elementArray[0]]) {
                    outlineDictionary[elementArray[0]] = [];
                  }
                  outlineDictionary[elementArray[0]] = [
                    ...outlineDictionary[elementArray[0]],
                    ...sliced,
                  ];
                  allOutlines.push(...sliced);
                }
                // Create a new object with the desired structure
                const transformedOutlineStructure = {};

                for (const key in outlineDictionary) {
                  const arrayLength = outlineDictionary[key].length;
                  transformedOutlineStructure[key] =
                    Array(arrayLength).fill("100");
                }

                setallOutlinesArray(allOutlines);
                setoutlineDictionaryStructure(outlineDictionary);
                setWordCounts(transformedOutlineStructure);
                modalToggle("outline_type1");
              } else {
                modalToggle("outline_type2");
              }
            }}
          >
            <div></div>
            <div>Generate Step Three (Blog) </div>
            <div className="">{loadingStep3 && <ThreeDotsLoader />}</div>
          </button>
          <div></div>
        </div>
        <div className="min-w-[50%] px-4 ">
          <TextEditor stringOutput={finalOutputString} />
        </div>
      </div>

      <div className="modal-box" id="outline_type2">
        <div className="popup-body">
          <div className="flex items-center justify-between">
            <h2 className="font-bold text-[30px]">Outline Type</h2>
            <div className="flex items-center justify-between">
              <h2 className="font-bold text-[24px]">
                Total Requested Words: {number_of_words}
              </h2>
              <button
                type="button"
                className="pop-btn ms-auto"
                onClick={() => modalToggle("outline_type2")}
              >
                <svg className="icon text-[42px]">
                  <use href="#icon_close"></use>
                </svg>
              </button>
            </div>
          </div>
          <div className="overflow-auto max-h-[500px]">
            <div className="grid grid-cols-5 text-center text-[18px] font-bold my-5">
              <div className="col-span-3">Outlines</div>
              <div>Number Of Words</div>
              {/* <div>Delete Outline</div> */}
            </div>
            {outlinesTotalModal?.map((outline, index) => (
              <div
                key={index}
                className="grid grid-cols-5 text-center content-center"
              >
                <div className="col-span-3">
                  <textarea
                    className="w-full"
                    type="text"
                    value={outline?.key}
                    onChange={(e) =>
                      // handleType2OutlineChange(index, e.target.value)
                      setoutlinesTotalModal((prev) =>
                        prev.map((item, i) =>
                          i === index ? { ...item, key: e.target.value } : item
                        )
                      )
                    }
                  />
                </div>
                <div>
                  <input
                    type="number"
                    className="w-[72px] text-center"
                    value={outline?.number_of_words}
                    onChange={(e) =>
                      // handleType2WordCountChange(index, e.target.value)
                      setoutlinesTotalModal((prev) =>
                        prev.map((item, i) =>
                          i === index
                            ? { ...item, number_of_words: e.target.value }
                            : item
                        )
                      )
                    }
                  />
                </div>
                {/* <div>
                  <button
                    type="button"
                    className="pop-btn ms-auto"
                    onClick={() => handleDeleteOutline(index)}
                  >
                    <svg className="icon text-[26px]">
                      <use href="#icon_delete"></use>
                    </svg>
                  </button>
                </div> */}
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center pt-3">
            <button
              type="submit"
              // disabled={loadingStep3}
              className="w-[40%] flex justify-between items-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
              onClick={() => {
                // setfinalOutputString("");
                if (onestepWS && onestepWS.readyState === WebSocket.OPEN) {
                  onestepWS?.close();
                  setonestepWS(null);
                }
                if (wsOutline && wsOutline.readyState === WebSocket.OPEN) {
                  wsOutline?.close();
                  setWswsOutline(null);
                }
                // generateBlogFn(type2Outlines);
                wrappedGenerateBlogFnNew(outlinesTotalModal);
                modalToggle("outline_type2");
              }}
            >
              <div></div>
              <div>Generate Blog</div>
              <div className="">{loadingStep3 && <ThreeDotsLoader />}</div>
            </button>
          </div>
        </div>
      </div>
      <div className="modal-box" id="outline_type1">
        <div className="popup-body">
          <div className="flex ">
            <h2 className="font-bold text-[30px]">Outline Type 01</h2>
            <button
              type="button"
              className="pop-btn ms-auto"
              onClick={() => modalToggle("outline_type1")}
            >
              <svg className="icon text-[42px]">
                <use href="#icon_close"></use>
              </svg>
            </button>
          </div>
          <div className="overflow-auto max-h-[500px]">
            <div className="grid grid-cols-5 text-center text-[20px] font-bold mt-3">
              <div className="col-span-3">Outlines</div>
              <div>Number Of Words</div>
              <div>Delete Outline</div>
            </div>
            {Object.keys(outlineDictionaryStructure || {}).map((section) => (
              <div key={section}>
                <div className="py-3 font-medium text-[18px]">{section}</div>
                {outlineDictionaryStructure[section].map(
                  (subSection, index) => (
                    <div className="grid grid-cols-5 text-center content-center">
                      <div className="col-span-3">
                        <textarea
                          className="w-full"
                          type="text"
                          value={subSection}
                          onChange={(e) => {
                            handleOutlineChange(section, index, e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <input
                          type="number"
                          className="w-[72px] text-center"
                          value={wordCounts[section][index]}
                          onChange={(e) => {
                            handleWordCountChange(
                              section,
                              index,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div>
                        <button
                          type="button"
                          className="pop-btn ms-auto"
                          onClick={() => handleDelete(section, index)}
                        >
                          <svg className="icon text-[26px]">
                            <use href="#icon_delete"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                  )
                )}
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center pt-3">
            <button
              type="submit"
              disabled={loadingStep3}
              className="w-[40%] flex justify-between items-center py-2 px-[20px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
              onClick={() => {
                const allValues = [];

                // Loop through the object and its arrays
                for (const key in outlineDictionaryStructure) {
                  if (Array.isArray(outlineDictionaryStructure[key])) {
                    // Concatenate the values to the allValues array
                    allValues.push(...outlineDictionaryStructure[key]);
                  }
                }
                // setfinalOutputString("");
                if (onestepWS && onestepWS.readyState === WebSocket.OPEN) {
                  onestepWS?.close();
                  setonestepWS(null);
                }
                if (wsOutline && wsOutline.readyState === WebSocket.OPEN) {
                  wsOutline?.close();
                  setWswsOutline(null);
                }
                // console.log(allValues, "all values");
                // generateBlogFn(allValues);
                modalToggle("outline_type1");
              }}
            >
              <div></div>
              <div>Generate Blog</div>
              <div className="">{loadingStep3 && <ThreeDotsLoader />}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThreeStepBlog;
