/* eslint-disable eqeqeq */
import { Routes, Route, useLocation } from "react-router-dom";
import Template from "../pages/Template";
import LayoutOutlet2 from "./LayoutOutlet2";
import AccountSetting from "../pages/AccountSetting";
import Member from "../pages/Member";
import Managespace from "../pages/Managespace";
import Billing from "../pages/Billing";
import SocialConnect from "../pages/SocialConnect";
import Mysetting from "../pages/Mysetting";
import Myworkspace from "../pages/Myworkspace";
import EngagingPostCaptionMain from "../pages/EngagingPostCaptonMain";
import Tweet from "../pages/Tweet";
import TiktokVideoCaption from "../pages/TiktokVideoCaption";
import QuoraAnswer from "../pages/QuoraAnswer";
import YoutubeVideoDescription from "../pages/YoutubeVideoDescription";
import DiscountPromotion from "../pages/DiscountPromotion";
import SocialMediaBio from "../pages/SocialMediaBio";
import FaqGenerator from "../pages/FaqGenerator";
import BusinessLandingPage from "../pages/BusinessLandingPage";
import AboutUs from "../pages/AboutUs";
import JobDescription from "../pages/JobDescription";
import ContentRewriter from "../pages/ContentRewriter";
import GoogleAdsHeadlines from "../pages/GoogleAdsHeadlines";
import FacebookAdsPrimaryText from "../pages/FacebookAdsPrimaryText";
import FacebookHeadlines from "../pages/FacebookHeadlines";
import Login from "../pages/Login";
import Login2 from "../pages/Login2";
import Signup from "../pages/Signup";
import Signup2 from "../pages/Signup2";
import EcommerceProductDescription from "../pages/EcommerceProductDescription";
import AmazonProductDecription from "../pages/AmazonProductDocument";
import EventPost from "../pages/EventPost";
import OfferPost from "../pages/OfferPost";
import ProductDescription from "../pages/ProductDescription";
import NewPost from "../pages/NewPost";
import SubscriberWelcome from "../pages/SubscriberWelcome";
import DiscountEmail from "../pages/DiscountEmail";
import EventPromotion from "../pages/EventPromotion";
import ProductLaunchEmail from "../pages/ProductLaunchEmail";
import VideoScript from "../pages/VideoScript";
import YoutubeChannelDescription from "../pages/YoutubeChannelDescription";
import GoogleLongHeadlines from "../pages/GoogleLongHeadLines";
import GoogleAdsDescription from "../pages/GoogleAdsDescription";
import EventHighlightPost from "../pages/EventHighlightPost";
import LinkedInSingleImagePost from "../pages/LinkedInSingleImagePost";
import BlogOutlines from "../pages/BlogOutlines";
import BlogIdeas from "../pages/BlogIdeas";
import BlogTitle from "../pages/BlogTitle";
import BlogSection from "../pages/BlogSection";
import OneStepBlog from "../pages/OneStepBlog";
import LinkedInPersonalPost from "../pages/LinkedInPersonalPost";
import LinkedCompanyPost from "../pages/LinkedCompanyPost";
import AmazonProductFeatures from "../pages/AmazonProductFeatures";
import ThreeStepBlog from "../pages/ThreeStepBlog";
import SeoMetaTitle from "../pages/SeoMetaTitle";
import SeoMetaDescription from "../pages/SeoMetaDescription";
import MermaidPage from "../pages/MermaidPage";
import YtChat from "../pages/YtChat";
import Chat from "../pages/Chat";
import DynamicPage from "../pages/DynamicPage";
import Workspace3 from "../pages/Workspace3";
import Diagram from "./Diagram";
import TransactionId from "../pages/TransactionId";
import PaymentSuccess from "../pages/PaymentSuccess";
import PaymentFailed from "../pages/PaymentFailed";
import Subscription from "../pages/Subscription";
import AsideBar from "./AsideBar";
import LoaderNew from "./LoaderNew";
import Game from "../pages/Game";
import ModelTraining from "../pages/ModelTraining";
import withExpirationCheck from "./withExpirationCheck";
import SocialLogin from "../pages/SocialLogin";
function Routing() {
  const location = useLocation();
  // console.log(location, "location...");

  // Define an array of paths where you want to render the AsideBar
  const asideBarPaths = [
    "/editor",
    "/add-workspace",
    "/",
    "/login-otp",
    "/signup",
    "/signup-otp",
    "/welcome-to-vison",
    "/account-settings",
    "/member",
    "/manage-space",
    "/billing",
    "/social-connect",
    "/subscription",
    "/my-settings",
    "/my-workspace",
    "/payment_success",
    "/payment_failed",
    "/audio",
    "/start-training",
    "/social/login",
  ];
  let locationCheck = "";
  // "/workspace/:type/:type_id/:request_id"
  if (
    location.pathname.includes("/template/folders") ||
    location.pathname.includes("/template/files")
  ) {
    locationCheck = "/template";
  } else if (
    location?.pathname?.includes("/workspace") &&
    location?.pathname?.split("/")?.length >= 3
  ) {
    locationCheck = "/";
  } else {
    locationCheck = location.pathname;
  }

  // Check if the current path is included in the asideBarPaths array
  const renderAsideBar = !asideBarPaths.includes(locationCheck);
  // Wrap components with the HOC
  const WrappedTemplate = withExpirationCheck(Template);
  const WrappedTransactionId = withExpirationCheck(TransactionId);
  const WrappedPaymentSuccess = withExpirationCheck(PaymentSuccess);
  const WrappedPaymentFailed = withExpirationCheck(PaymentFailed);
  const WrappedLayoutOutlet2 = withExpirationCheck(LayoutOutlet2);
  const WrappedAccountSetting = withExpirationCheck(AccountSetting);
  const WrappedMember = withExpirationCheck(Member);
  const WrappedManagespace = withExpirationCheck(Managespace);
  const WrappedBilling = withExpirationCheck(Billing);
  const WrappedSocialConnect = withExpirationCheck(SocialConnect);
  const WrappedSubscription = withExpirationCheck(Subscription);
  const WrappedMysetting = withExpirationCheck(Mysetting);
  const WrappedMyworkspace = withExpirationCheck(Myworkspace);
  const WrappedChat = withExpirationCheck(Chat);
  const WrappedYtChat = withExpirationCheck(YtChat);
  const WrappedEngagingPostCaptionMain = withExpirationCheck(
    EngagingPostCaptionMain
  );
  const WrappedTweet = withExpirationCheck(Tweet);
  const WrappedTiktokVideoCaption = withExpirationCheck(TiktokVideoCaption);
  const WrappedQuoraAnswer = withExpirationCheck(QuoraAnswer);
  const WrappedYoutubeVideoDescription = withExpirationCheck(
    YoutubeVideoDescription
  );
  const WrappedDiscountPromotion = withExpirationCheck(DiscountPromotion);
  const WrappedSocialMediaBio = withExpirationCheck(SocialMediaBio);
  const WrappedFaqGenerator = withExpirationCheck(FaqGenerator);
  const WrappedBusinessLandingPage = withExpirationCheck(BusinessLandingPage);
  const WrappedAboutUs = withExpirationCheck(AboutUs);
  const WrappedJobDescription = withExpirationCheck(JobDescription);
  const WrappedContentRewriter = withExpirationCheck(ContentRewriter);
  const WrappedGoogleAdsHeadlines = withExpirationCheck(GoogleAdsHeadlines);
  const WrappedGoogleLongHeadlines = withExpirationCheck(GoogleLongHeadlines);
  const WrappedGoogleAdsDescription = withExpirationCheck(GoogleAdsDescription);
  const WrappedFacebookAdsPrimaryText = withExpirationCheck(
    FacebookAdsPrimaryText
  );
  const WrappedFacebookHeadlines = withExpirationCheck(FacebookHeadlines);
  const WrappedEcommerceProductDescription = withExpirationCheck(
    EcommerceProductDescription
  );
  const WrappedAmazonProductDecription = withExpirationCheck(
    AmazonProductDecription
  );
  const WrappedAmazonProductFeatures = withExpirationCheck(
    AmazonProductFeatures
  );
  const WrappedEventPost = withExpirationCheck(EventPost);
  const WrappedOfferPost = withExpirationCheck(OfferPost);
  const WrappedProductDescription = withExpirationCheck(ProductDescription);
  const WrappedNewPost = withExpirationCheck(NewPost);
  const WrappedSubscriberWelcome = withExpirationCheck(SubscriberWelcome);
  const WrappedDiscountEmail = withExpirationCheck(DiscountEmail);
  const WrappedEventPromotion = withExpirationCheck(EventPromotion);
  const WrappedProductLaunchEmail = withExpirationCheck(ProductLaunchEmail);
  const WrappedVideoScript = withExpirationCheck(VideoScript);
  const WrappedYoutubeChannelDescription = withExpirationCheck(
    YoutubeChannelDescription
  );
  const WrappedLinkedInSingleImagePost = withExpirationCheck(
    LinkedInSingleImagePost
  );
  const WrappedLinkedInPersonalPost = withExpirationCheck(LinkedInPersonalPost);
  const WrappedLinkedCompanyPost = withExpirationCheck(LinkedCompanyPost);
  const WrappedEventHighlightPost = withExpirationCheck(EventHighlightPost);
  const WrappedBlogOutlines = withExpirationCheck(BlogOutlines);
  const WrappedBlogIdeas = withExpirationCheck(BlogIdeas);
  const WrappedBlogTitle = withExpirationCheck(BlogTitle);
  const WrappedBlogSection = withExpirationCheck(BlogSection);
  const WrappedOneStepBlog = withExpirationCheck(OneStepBlog);
  const WrappedThreeStepBlog = withExpirationCheck(ThreeStepBlog);
  const WrappedSeoMetaTitle = withExpirationCheck(SeoMetaTitle);
  const WrappedSeoMetaDescription = withExpirationCheck(SeoMetaDescription);
  const WrappedMermaidPage = withExpirationCheck(MermaidPage);
  const WrappedDynamicPage = withExpirationCheck(DynamicPage);
  const WrappedDiagram = withExpirationCheck(Diagram);
  const WrappedGame = withExpirationCheck(Game);
  const WrappedModelTraining = withExpirationCheck(ModelTraining);

  return (
    <>
      {renderAsideBar && <AsideBar />}
      <Routes>
        <Route path="/add-workspace" element={<Workspace3 />} />
        <Route path="/" element={<Login />} />
        <Route path="/login-otp" element={<Login2 />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/welcome-to-vison" element={<LoaderNew />} />
        <Route path="/signup-otp" element={<Signup2 />} />
        <Route path="/social/login" element={<SocialLogin />} />
        <Route path="/template" element={<WrappedTemplate />} />
        <Route
          path="/template/folders/:space_name/:space_id"
          element={<WrappedTemplate />}
        />
        <Route
          path="/template/files/:space_name/:space_id/:folder_name/:folder_id"
          element={<WrappedTemplate />}
        />
        <Route
          path="/payment/status/:transaction-id"
          element={<WrappedTransactionId />}
        />
        <Route path="/diagram" element={<WrappedDiagram />} />

        <Route path="/payment_success" element={<WrappedPaymentSuccess />} />
        <Route path="/payment_failed" element={<WrappedPaymentFailed />} />
        <Route path="/" element={<WrappedLayoutOutlet2 />}>
          <Route path="/account-settings" element={<WrappedAccountSetting />} />
          <Route path="/member" element={<WrappedMember />} />
          <Route path="/manage-space" element={<WrappedManagespace />} />
          <Route path="/billing" element={<WrappedBilling />} />
          <Route path="/social-connect" element={<WrappedSocialConnect/>}/>
          <Route path="/subscription" element={<WrappedSubscription />} />
          <Route path="/my-settings" element={<WrappedMysetting />} />
          <Route path="/my-workspace" element={<WrappedMyworkspace />} />
        </Route>
        <Route path="/template/chat" element={<WrappedChat />} />
        <Route path="/template/youtube-chat" element={<WrappedYtChat />} />
        <Route
          path="/template/engaging-post-caption"
          element={<WrappedEngagingPostCaptionMain />}
        />
        <Route path="/template/tweet" element={<WrappedTweet />} />
        <Route
          path="/template/tiktok-video-caption"
          element={<WrappedTiktokVideoCaption />}
        />
        <Route path="/template/quora-answer" element={<WrappedQuoraAnswer />} />
        <Route
          path="/template/youtube-video-description"
          element={<WrappedYoutubeVideoDescription />}
        />
        <Route
          path="/template/discount-promotion"
          element={<WrappedDiscountPromotion />}
        />
        <Route
          path="/template/social-media-bio"
          element={<WrappedSocialMediaBio />}
        />
        <Route
          path="/template/faq-generator"
          element={<WrappedFaqGenerator />}
        />
        <Route
          path="/template/landing-page"
          element={<WrappedBusinessLandingPage />}
        />
        <Route path="/template/about-us" element={<WrappedAboutUs />} />
        <Route
          path="/template/job-description"
          element={<WrappedJobDescription />}
        />
        <Route
          path="/template/content-rewriter"
          element={<WrappedContentRewriter />}
        />
        <Route
          path="/template/google-ads-headlines"
          element={<WrappedGoogleAdsHeadlines />}
        />
        <Route
          path="/template/google-ads-long-headlines"
          element={<WrappedGoogleLongHeadlines />}
        />
        <Route
          path="/template/google-ads-description"
          element={<WrappedGoogleAdsDescription />}
        />
        <Route
          path="/template/facebook-ads-primary-text"
          element={<WrappedFacebookAdsPrimaryText />}
        />
        <Route
          path="/template/facebook-ads-headlines"
          element={<WrappedFacebookHeadlines />}
        />
        <Route
          path="/template/product-description"
          element={<WrappedEcommerceProductDescription />}
        />
        <Route
          path="/template/amazon-product-description"
          element={<WrappedAmazonProductDecription />}
        />
        <Route
          path="/template/amazon-product-features"
          element={<WrappedAmazonProductFeatures />}
        />
        <Route path="/template/event-post" element={<WrappedEventPost />} />
        <Route path="/template/offer-post" element={<WrappedOfferPost />} />
        <Route
          path="/template/marketing-product-description"
          element={<WrappedProductDescription />}
        />
        <Route path="/template/new-post" element={<WrappedNewPost />} />
        <Route
          path="/template/subscriber-welcome-email"
          element={<WrappedSubscriberWelcome />}
        />
        <Route
          path="/template/discount-email"
          element={<WrappedDiscountEmail />}
        />
        <Route
          path="/template/event-promotion-email"
          element={<WrappedEventPromotion />}
        />
        <Route
          path="/template/product-launch-email"
          element={<WrappedProductLaunchEmail />}
        />
        <Route path="/template/video-script" element={<WrappedVideoScript />} />
        <Route
          path="/template/youtube-channel-description"
          element={<WrappedYoutubeChannelDescription />}
        />
        <Route
          path="/template/linkedIn-single-image-post"
          element={<WrappedLinkedInSingleImagePost />}
        />
        <Route
          path="/template/linkedIn-personal-post"
          element={<WrappedLinkedInPersonalPost />}
        />
        <Route
          path="/template/linkedIn-company-post"
          element={<WrappedLinkedCompanyPost />}
        />
        <Route
          path="/template/event-highlight-post"
          element={<WrappedEventHighlightPost />}
        />
        <Route
          path="/template/blog-outlines"
          element={<WrappedBlogOutlines />}
        />
        <Route path="/template/blog-idea" element={<WrappedBlogIdeas />} />
        <Route path="/template/blog-title" element={<WrappedBlogTitle />} />
        <Route path="/template/blog-section" element={<WrappedBlogSection />} />
        <Route
          path="/template/one-step-blog"
          element={<WrappedOneStepBlog />}
        />
        <Route
          path="/template/three-step-blog"
          element={<WrappedThreeStepBlog />}
        />
        <Route
          path="/template/seo-meta-title"
          element={<WrappedSeoMetaTitle />}
        />
        <Route
          path="/template/seo-meta-description"
          element={<WrappedSeoMetaDescription />}
        />
        <Route path="/template/flow-chart" element={<WrappedMermaidPage />} />
        <Route
          path="/workspace/:type/:type_id/:request_id"
          element={<WrappedDynamicPage />}
        />
        <Route path="/start-training" element={<WrappedGame />} />
        <Route path="/model-training" element={<WrappedModelTraining />} />
      </Routes>
    </>
  );
}

export default Routing;
