export const allTemplates = [
  {
    title: "Blog",
    icon: "#icon_blog",
    key_name: "template_blog",
    description: "Revamp your blogging experience for effortless blog creation",
  },
  {
    title: "SEO",
    icon: "#icon_seo",
    key_name: "template_seo",
    description:
      "Optimize your SEO Strategy with compelling Meta Titles and Descriptions",
  },
  {
    title: "Social Media",
    icon: "#icon_socialmedia",
    key_name: "template_socialmedia",
    description:
      "Enhance & Boost your Social Media presence across all platforms",
  },
  {
    title: "Business",
    icon: "#icon_business",
    key_name: "template_business",
    description:
      "Upgrade your Business with a suite of templates & enhance your online presence",
  },
  {
    title: "E-commerce",
    icon: "#icon_ecommerce",
    key_name: "template_ecommerce",
    description:
      "Drive E-commerce success with captivating Product Descriptions & Features",
  },
  {
    title: "Marketing",
    icon: "#icon_marketing",
    key_name: "template_marketing",
    description: "Revolutionize your Marketing Strategy with dynamic tools",
  },
  {
    title: "Email",
    icon: "#icon_email",
    key_name: "template_email",
    description: "Maximize your Email impact to drive conversions effortlessly",
  },
  {
    title: "Video",
    icon: "#icon_video",
    key_name: "template_video",
    description:
      "Enhance your Video impact with dynamic scripts and descriptions",
  },
  // {
  //   title: "Website Copy",
  //   icon: "#icon_website",
  // },
];
export const templatesAndNavigation = {
  Blog: [
    {
      title: "One Step Blog",
      description: "Craft captivating Blogs in just one click",
      navigation: "/template/one-step-blog",
    },
    // {
    //   title: "Two Step Blog",
    //   description: "",
    //   navigation: "/template/two-step-blog",
    // },
    {
      title: "Three Step Blog",
      description: "Craft compelling Blogs in just three steps",
      navigation: "/template/three-step-blog",
      key_name: "template_blog_three_step",
    },
    {
      title: "Blog Title",
      description: "Craft intriguing Blog Titles to captivate readers",
      navigation: "/template/blog-title",
    },
    {
      title: "Blog Outlines",
      description: "Craft engaging Blog Outlines for your Blog",
      navigation: "/template/blog-outlines",
    },
    {
      title: "Blog Ideas",
      description: "Generate top-notch Blog Ideas",
      navigation: "/template/blog-idea",
    },
    {
      title: "Blog Section",
      description: "Craft engaging Blog Sections for your Blog",
      navigation: "/template/blog-section",
    },
  ],
  SEO: [
    {
      title: "SEO Meta Title",
      description: "Generate Meta Title Optimized for Search Engine",
      navigation: "/template/seo-meta-title",
    },
    {
      title: "SEO Meta Description",
      description: "Generate Meta Description Optimized for Search Engine",
      navigation: "/template/seo-meta-description",
    },
  ],
  Business: [
    {
      title: "FAQ Generator",
      description:
        "Develop informative FAQ to address user inquiries efficiently.",
      navigation: "/template/faq-generator",
    },
    {
      title: "Landing Page",
      description:
        "Design a captivating, and high-converting landing page to drive conversions and revenue growth",
      navigation: "/template/landing-page",
    },
    {
      title: "About Us",
      description:
        "Craft an engaging About Us page to convey your brand story and values authentically",
      navigation: "/template/about-us",
    },
    {
      title: "Job Description",
      description: "Write a brief Job Description to attract top talent",
      navigation: "/template/job-description",
    },
    {
      title: "Content Rewriter",
      description:
        "Rewrite content efficiently while preserving clarity and authenticity",
      navigation: "/template/content-rewriter",
    },
    {
      title: "Google Headlines",
      description:
        "Generate a Catchy Headline for the Company or Product for Google Ads",
      navigation: "/template/google-ads-headlines",
    },
    {
      title: "Google Long Headlines",
      description:
        "Generate an engaging Long Headline for the Company or Product for Google Ads",
      navigation: "/template/google-ads-long-headlines",
    },
    {
      title: "Google Ads Description",
      description:
        "Generate a brief eye-grabbing Description of the Company/Product ",
      navigation: "/template/google-ads-description",
    },
    {
      title: "Facebook Ads Primary Text",
      description:
        "Generate compelling Facebook Ads Primary Text to boost engagement and leads for your product/brand.",
      navigation: "/template/facebook-ads-primary-text",
    },
    {
      title: "Facebook Ads Headlines",
      description:
        "Generate attention-grabbing Facebook Ad headlines for your product/brand",
      navigation: "/template/facebook-ads-headlines",
    },
    {
      title: "LinkedIn Single Image Ad/Post",
      description:
        "Generate Single Image Ad/Post To Bring in more customers for your product/brand",
      navigation: "/template/linkedIn-single-image-post",
    },
    {
      title: "Flow Chart",
      modified_title: "Diagram and Charts",
      description:
        "Create engaging Flowcharts to effortlessly simplify complex concepts ",
      navigation: "/template/flow-chart",
    },
  ],
  "Social Media": [
    {
      title: "Social Media Bio",
      description: "Generate an engaging bio for your Social Media In seconds",
      navigation: "/template/social-media-bio",
    },
    {
      title: "Engaging Post Caption",
      description: "Craft an engaging post caption for Social Media Platforms",
      navigation: "/template/engaging-post-caption",
    },
    {
      title: "Tiktok Video Caption",
      description: "Generate eye-catching captions tailored for TikTok videos",
      navigation: "/template/tiktok-video-caption",
    },
    {
      title: "Tweet",
      description: "Craft concise and compelling Tweets to enhance engagement",
      navigation: "/template/tweet",
    },
    {
      title: "Quora Answers",
      description:
        "Provide insightful and engaging Quora answers to address user queries effectively.",
      navigation: "/template/quora-answer",
    },
    {
      title: "Youtube Video Description",
      description:
        "Craft captivating YouTube Video Descriptions to maximize viewer engagement",
      navigation: "/template/youtube-video-description",
    },
    {
      title: "Event Highlight Post",
      description:
        "Generate attention-grabbing Event Highlight Posts to showcase memorable moments.",
      navigation: "/template/event-highlight-post",
    },
    {
      title: "Discount Promotion",
      description: "Create enticing Discount Promotion messages to drive sales",
      navigation: "/template/discount-promotion",
    },
  ],
  Video: [
    {
      title: "Video Script",
      description:
        "Craft dynamic Video Scripts to convey your message effectively",
      navigation: "/template/video-script",
    },
    {
      title: "YouTube Channel Description",
      description:
        "Craft informative Youtube Channel Descriptions to intrigue your audience",
      navigation: "/template/youtube-channel-description",
    },
  ],
  Email: [
    {
      title: "Subscriber Welcome Email",
      description:
        "Create warm and engaging emails to welcome your New Subscribers",
      navigation: "/template/subscriber-welcome-email",
    },
    {
      title: "Discount Email",
      description: "Craft irresistible Discount Emails to entice New Customers",
      navigation: "/template/discount-email",
    },
    {
      title: "Event Promotion Email",
      description:
        "Craft captivating Event Promotion Emails to invite and excite subscribers for upcoming events",
      navigation: "/template/event-promotion-email",
    },
    {
      title: "Product Launch Email",
      description:
        "Craft exciting Product Launch Emails to captivate your Audience",
      navigation: "/template/product-launch-email",
    },
  ],
  Marketing: [
    {
      title: "Google My Business- Event Post",
      description: "Craft Vibrant Event Posts for Google My Business",
      navigation: "/template/event-post",
    },
    {
      title: "Google By Business- Offer Post",
      description: "Craft irresistible Offer Posts for Google My Business",
      navigation: "/template/offer-post",
    },
    {
      title: "Google By Business- Product Description",
      description:
        "Create informative Product Descriptions for Google My Business",
      navigation: "/template/marketing-product-description",
    },
    {
      title: "Google By Business- New Post",
      description: "Craft attention-grabbing New Posts for Google My Business",
      navigation: "/template/new-post",
    },
    {
      title: "LinkedIn Personal Post (Get Max Reach And Engagement)",
      description:
        "Craft attractive LinkedIn Personal Posts to engage your Network",
      navigation: "/template/linkedIn-personal-post",
    },
    {
      title: "LinkedIn Company Post (Get Max Reach And Engagement)",
      description:
        "Craft compelling LinkedIn posts for your Company to drive brand visibility",
      navigation: "/template/linkedIn-company-post",
    },
  ],
  "E-commerce": [
    {
      title: "Product Description",
      description:
        "Craft compelling product descriptions to captivate shoppers",
      navigation: "/template/product-description",
    },
    {
      title: "Amazon Product Description",
      description:
        "Craft irresistible Amazon Product descriptions to entice shoppers",
      navigation: "/template/amazon-product-description",
    },
    {
      title: "Amazon Product Features",
      description:
        "Craft enticing Amazon Product Features to showcase the standout benefits",
      navigation: "/template/amazon-product-features",
    },
  ],
};
