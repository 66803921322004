/* eslint-disable eqeqeq */
import { templatesAndNavigation } from "../constants/TemplateAndNavigation";

const useCommonFunctions = () => {
  const getLetters = (name) => {
    if (!name) return "";
    let nameArray = name?.trim()?.split(" ");
    // console.log(nameArray, "nameArray");
    if (nameArray?.length > 1) {
      return nameArray[0][0]?.toUpperCase() + nameArray[1][0]?.toUpperCase();
    } else {
      return nameArray[0][0]?.toUpperCase();
    }
  };
  function getDate() {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const date = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${date}`;
  }
  const getBackgroundColor = (letter) => {
    if (letter && typeof letter === "string" && letter.length > 0) {
      switch (letter?.toUpperCase()) {
        case "A":
          return "#8B0000"; // Dark red
        case "B":
          return "#2F4F4F"; // Dark slate gray
        case "C":
          return "#556B2F"; // Dark olive green
        case "D":
          return "#8B4513"; // Saddle brown
        case "E":
          return "#483D8B"; // Dark slate blue
        case "F":
          return "#800080"; // Purple
        case "G":
          return "#006400"; // Dark green
        case "H":
          return "#8B008B"; // Dark magenta
        case "I":
          return "#2F4F4F"; // Dark slate gray
        case "J":
          return "#800000"; // Maroon
        case "K":
          return "#4B0082"; // Indigo
        case "L":
          return "#556B2F"; // Dark olive green
        case "M":
          return "#8B4513"; // Saddle brown
        case "N":
          return "#556B2F"; // Dark olive green
        case "O":
          return "#8B008B"; // Dark magenta
        case "P":
          return "#800080"; // Purple
        case "Q":
          return "#8B4513"; // Saddle brown
        case "R":
          return "#2F4F4F"; // Dark slate gray
        case "S":
          return "#4B0082"; // Indigo
        case "T":
          return "#556B2F"; // Dark olive green
        case "U":
          return "#800000"; // Maroon
        case "V":
          return "#483D8B"; // Dark slate blue
        case "W":
          return "#006400"; // Dark green
        case "X":
          return "#8B0000"; // Dark red
        case "Y":
          return "#2F4F4F"; // Dark slate gray
        case "Z":
          return "#556B2F"; // Dark olive green
        default:
          return "red"; // Default color if the letter doesn't match any case
      }
    } else {
      return "red";
    }
  };
  const getPlanDetails = () => {
    const planId = JSON.parse(localStorage.getItem("login_data"))?.resp_user
      ?.plan_id;
    const subscriptionId = JSON.parse(localStorage.getItem("login_data"))
      ?.resp_user?.subscription_id;
    const usersetting = JSON.parse(localStorage.getItem("login_data"))
      ?.resp_user?.usersetting;
    const wordCount = parseInt(
      usersetting?.find((item) => item?.key_name == "template_word")?.key_value
    );
    return { planId, subscriptionId, usersetting, wordCount };
  };
  const checkEnabled = (key_name) => {
    const usersetting = JSON.parse(localStorage.getItem("login_data"))
      ?.resp_user?.usersetting;
    let find = usersetting?.find((item) => item?.key_name == key_name);
    if (find && find.key_value == "N") {
      return false;
    } else {
      return true;
    }
  };

  const checkWorkspaceEnabled = () => {
    const usersetting = JSON.parse(localStorage.getItem("login_data"))
      ?.resp_user?.usersetting;
    let find = usersetting?.find((item) => item?.key_name == "work_space");
    if (find && parseInt(find.key_value) <= 0) {
      return false;
    } else {
      return true;
    }
  };
  const checkInviteEnabled = () => {
    const usersetting = JSON.parse(localStorage.getItem("login_data"))
      ?.resp_user?.usersetting;
    let find = usersetting?.find((item) => item?.key_name == "seat");
    if (find && parseInt(find.key_value) <= 0) {
      return false;
    } else {
      return true;
    }
  };
  const gettemplateWordCount = () => {
    const usersetting = JSON.parse(localStorage.getItem("login_data"))
      ?.resp_user?.usersetting;
    let find = usersetting?.find((item) => item?.key_name == "template_word");
    return parseInt(find?.key_value);
  };
  const replacingWordCount = (bal_template_word) => {
    let login_data = JSON.parse(localStorage.getItem("login_data"));
    let user_setting = login_data?.resp_user?.usersetting;
    let user_setting_modified = user_setting?.map((item) => {
      if (item?.key_name == "template_word") {
        item.key_value = bal_template_word;
      }
      return item;
    });
    localStorage.setItem(
      "login_data",
      JSON.stringify({
        ...login_data,
        resp_user: {
          ...login_data?.resp_user,
          usersetting: user_setting_modified,
        },
      })
    );
  };
  const replacingWordValue = (key_name, key_value) => {
    let login_data = JSON.parse(localStorage.getItem("login_data"));
    let user_setting = login_data?.resp_user?.usersetting;
    let user_setting_modified = user_setting?.map((item) => {
      if (item?.key_name == key_name) {
        item.key_value = key_value;
      }
      return item;
    });
    localStorage.setItem(
      "login_data",
      JSON.stringify({
        ...login_data,
        resp_user: {
          ...login_data?.resp_user,
          usersetting: user_setting_modified,
        },
      })
    );
  };
  const getDescription = (mainTemplate, subTemplate) => {
    let array = templatesAndNavigation[mainTemplate];
    let description = array?.find((item) => {
      return item?.title == subTemplate;
    })?.description;
    return description || "";
  };
  const getFileIcon = (fileName) => {
    const ext = fileName.split(".").pop();
    if (
      ext === "pdf" ||
      ext === "doc" ||
      ext === "docx" ||
      ext === "xls" ||
      ext === "xlsx" ||
      ext === "jpg" ||
      ext === "jpeg" ||
      ext === "png"
    ) {
      switch (ext) {
        case "pdf":
          return {
            icon: "/images/pdf.svg",
            ext: ext,
          }; // PDF icon
        case "doc":
        case "docx":
          return {
            icon: "/images/doc.svg",
            ext: ext,
          }; // Word icon
        case "xls":
        case "xlsx":
          return {
            icon: "/images/xls.svg",
            ext: ext,
          }; // Excel icon
        case "jpg":
        case "jpeg":
        case "png":
          return {
            icon: "🖼️",
            ext: ext,
          }; // Image icon
        default:
          return {
            icon: "📁",
            ext: ext,
          }; // Default file icon
      }
    } else {
      return {
        icon: "/images/url.svg",
        ext: "url",
      };
    }
  };

  return {
    getLetters,
    getDate,
    getBackgroundColor,
    getPlanDetails,
    checkEnabled,
    replacingWordCount,
    checkWorkspaceEnabled,
    gettemplateWordCount,
    checkInviteEnabled,
    replacingWordValue,
    getDescription,
    getFileIcon
  };
};
export default useCommonFunctions;
